import { Stack, Tooltip, Typography, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"

interface SystemTagProps {
    small?: boolean
}

export const SystemTag = (props: SystemTagProps) => {
    const { small } = props
    const theme = useTheme()
    const { t } = useTranslation()

    return (
        <Tooltip title={t("alarm.system_tooltip")} disableInteractive>
            <Stack
                sx={{
                    height: small ? 16 : 18,
                    px: "5px",
                    borderRadius: "4px",
                    backgroundColor: theme.palette.grey[600],
                    color: "black",
                    mixBlendMode: "exclusion",
                }}
            >
                <Typography variant={small ? "caption" : "body2"} align="center" fontWeight="bold">
                    {t("alarm.system_short")}
                </Typography>
            </Stack>
        </Tooltip>
    )
}
