import { Login } from "@mui/icons-material"
import { Button, Dialog, DialogActions, DialogContent, Grid, TextField, Typography, useTheme } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { retainConfig } from "../config/urls"
import { validateNonEmpty } from "../widgets/validation"
import { ETDialogTitle } from "./ETDialogTitle"

export interface LoginDialogProps {
    messages: string[]
    onLogin: (username: string, password: string) => void
}

export const LoginDialog = (props: LoginDialogProps) => {
    const { messages, onLogin } = props
    const [username, setUsername] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [emailError, setEmailError] = React.useState("")
    const [passwordError, setPasswordError] = React.useState("")

    const { t } = useTranslation()
    const theme = useTheme()
    const navigate = useNavigate()

    const hasError = () => !checkUsername(username) || !checkPassword(password)

    const onClickLogin = () => {
        if (hasError()) {
            return
        }
        onLogin(username, password)
    }

    const setUsernameChecked = (s: string) => {
        setUsername(s)
        checkUsername(s)
    }
    const checkUsername = (s?: string) => validateNonEmpty(s, t, setEmailError)

    const setPasswordChecked = (s: string) => {
        setPassword(s)
        checkPassword(s)
    }
    const checkPassword = (s?: string) => validateNonEmpty(s, t, setPasswordError)

    return (
        <Dialog aria-labelledby="login-dialog" maxWidth="xs" open fullWidth>
            <ETDialogTitle title={t("dialog.login.title")} />
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            id="Username"
                            label={t("field.username")}
                            autoComplete="username"
                            autoCorrect="off"
                            autoCapitalize="none"
                            error={!!emailError}
                            helperText={emailError}
                            onChange={(event) => setUsernameChecked(event.target.value)}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="Password"
                            label={t("field.password")}
                            autoComplete="current-password"
                            autoCorrect="off"
                            autoCapitalize="none"
                            type="password"
                            error={!!passwordError}
                            helperText={passwordError}
                            onChange={(event) => setPasswordChecked(event.target.value)}
                            fullWidth={true}
                        />
                    </Grid>
                    {messages.map((message, i) => (
                        <Grid key={i} item xs={12}>
                            <Typography color={theme.palette.error.main}>{message}</Typography>
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => navigate(retainConfig({ pathname: "/recovery" }))} variant="outlined">
                    {t("action.reset_password")}
                </Button>
                <Button startIcon={<Login />} onClick={onClickLogin} variant="contained" color="primary">
                    {t("action.sign_in")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
