import { Box, Grid, Stack, Typography, useTheme } from "@mui/material"
import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { Site, Unit } from "../../api/Customer"
import { OneDayRange, RangeChoice } from "../common/RangeChoice"
import { useIoT, useIoTValues, ValidValue } from "../../services/IoT"
import { ConnectionStatus } from "../common/ConnectionStatus"
import { NewMultiChart } from "../common/NewMultiChart"

export interface NewWeatherProps {
    site: Site
    unit: Unit
}

const weatherClass = "weather"
const classes = [weatherClass]

const metersUnit = "m"
const kilometersPerHourUnit = "km/h"
const celsiusUnit = "°C"
const degreeSymbol = "°"
const redColors = ["C7253E", "#C40C0C", "#FF6500", "#FF8A08", "#FFC100"]

export function NewWeather(props: NewWeatherProps) {
    const { site, unit } = props

    const [range, setRange] = useState(OneDayRange)
    const theme = useTheme()
    const { t } = useTranslation()

    const { online, clients, items, values } = useIoT(site, unit.ShortName, true, classes)
    const [weatherIcon] = useIoTValues(weatherClass, "icon", clients, items, values)
    const [temperature] = useIoTValues(weatherClass, "temperature", clients, items, values)
    const [apparentTemperature] = useIoTValues(weatherClass, "feels_like", clients, items, values)
    const [windSpeed] = useIoTValues(weatherClass, "wind_speed", clients, items, values)
    const [windDirection] = useIoTValues(weatherClass, "wind_direction", clients, items, values)
    const [rainVolume] = useIoTValues(weatherClass, "rain_volume", clients, items, values)
    const [snowVolume] = useIoTValues(weatherClass, "snow_volume", clients, items, values)
    const [visibility] = useIoTValues(weatherClass, "visibility", clients, items, values)
    const [humidity] = useIoTValues(weatherClass, "humidity", clients, items, values)

    const renderValue = useCallback(
        (label: string, value?: ValidValue, valueUnit?: string) =>
            value ? (
                <Grid item xs={12} sm={6} md={3} lg={2}>
                    <Stack sx={{ backgroundColor: theme.palette.background.paper }} p={1}>
                        <Typography>{label}</Typography>
                        <Typography color={theme.palette.primary.main}>
                            {value.value?.number + ` ${valueUnit === undefined ? "" : valueUnit}`}
                        </Typography>
                    </Stack>
                </Grid>
            ) : null,
        [theme]
    )

    const renderWeatherIcon = (weatherIcon: ValidValue) => {
        return weatherIcon && weatherIcon.value?.text !== undefined ? (
            <Grid item xs={12} sm={6} md={3} lg={2}>
                <img src={weatherIcon.value?.text} alt={t("weather.pictogram")} width="50" />
            </Grid>
        ) : null
    }

    return (
        <Stack sx={{ py: 2, px: 4, alignItems: "center", width: "100%" }}>
            <Box sx={{ width: "min(100%,1280px)" }}>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Box flexGrow={1}>
                        <ConnectionStatus online={online} />
                    </Box>
                    <RangeChoice value={range} onChange={setRange} />
                </Stack>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Stack direction="row" sx={{ alignItems: "center" }} spacing={2}>
                            <Typography variant="h5">{t("offering.weather")}</Typography>
                            {renderWeatherIcon(weatherIcon)}
                        </Stack>
                    </Grid>
                    {renderValue(t("weather.temperature"), temperature, celsiusUnit)}
                    {renderValue(t("weather.feels_like"), apparentTemperature, celsiusUnit)}
                    {renderValue(t("weather.wind_speed"), windSpeed, kilometersPerHourUnit)}
                    {renderValue(t("weather.wind_direction"), windDirection, degreeSymbol)}
                    {renderValue(t("weather.rain_volume"), rainVolume, metersUnit)}
                    {renderValue(t("weather.humidity"), humidity, "%")}
                    {renderValue(t("weather.snow_volume"), snowVolume, metersUnit)}
                    {renderValue(t("weather.visibility"), visibility, metersUnit)}
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("weather.temperature_history")}</Typography>
                    </Grid>
                    {/* TODO: In the future need to change this to temperature of the unit.
                    Currently, using location temperature as a placeholder since we don't have backend
                    ready for unit temperature from the sensors. */}
                    <NewMultiChart
                        siteID={site.ID}
                        unit={unit}
                        itemValues={temperature ? [temperature] : []}
                        label={() => t("weather.temperature")}
                        timeOffsetMS={range.millis}
                        valueUnit={celsiusUnit}
                        colorPalette={redColors}
                        startValue={0}
                        rulers={[
                            {
                                label: "0 °C",
                                value: 0,
                                color: "red",
                            },
                        ]}
                    />
                </Grid>
            </Box>
        </Stack>
    )
}
