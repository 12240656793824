import { KeyboardArrowDown, KeyboardArrowUp, PentagonOutlined } from "@mui/icons-material"
import { Box, Grid, IconButton, Stack, Tooltip, Typography, useTheme } from "@mui/material"
import { t } from "i18next"
import { useMemo } from "react"
import { Operation } from "../../api/Authz"
import { Unit } from "../../api/Customer"
import { usePartitionPermission } from "../../auth/AuthorizerProvider"
import { Partition, Zone } from "./Alarm"
import { PartitionInfo } from "./PartitionInfo"
import { SwitchHistoryChart } from "./SwitchHistoryChart"
import { SystemTag } from "./SystemTag"
import { ZoneInfo } from "./ZoneInfo"

export interface PartitionBlockProps {
    partition: Partition
    armed: boolean
    alarm: boolean
    alarmInMemory: boolean
    showDetails: boolean
    unit: Unit
    zones: Zone[]
    zoneState: (zone: Zone, item: string) => boolean | undefined
    flipArmed: (on: boolean) => void
    flipBypass: (on: boolean, zone: Zone) => void
    flipDetails: () => void
}

export const PartitionBlock = (props: PartitionBlockProps) => {
    const {
        partition,
        armed,
        alarm,
        alarmInMemory,
        showDetails,
        unit,
        zones,
        zoneState,
        flipArmed,
        flipBypass,
        flipDetails,
    } = props

    const theme = useTheme()

    const armOperation = partition.isSystem ? Operation.ARM_SYSTEM_PARTITION : Operation.ARM_PARTITION
    const disarmOperation = partition.isSystem ? Operation.DISARM_SYSTEM_PARTITION : Operation.DISARM_PARTITION
    const bypassOperation = partition.isSystem ? Operation.BYPASS_SYSTEM_ZONE : Operation.BYPASS_ZONE
    const allowDisarm = usePartitionPermission(disarmOperation, unit, partition.alarmID, partition.id)
    const allowArm = usePartitionPermission(armOperation, unit, partition.alarmID, partition.id)
    const allowBypass = usePartitionPermission(bypassOperation, unit, partition.alarmID, partition.id)

    const filteredZones = useMemo(() => zones.filter((z) => z.partitionID === partition.id), [zones, partition.id])

    const partitionLabel = (p: Partition) => p.displayName || t("alarm.partition_display_name", { id: p.id })
    const partitionTooltip = (p: Partition) =>
        `${t("alarm.partition_display_name", { id: p.id })}${!p.displayName ? "" : ": " + p.displayName}.`
    const zoneLabel = (z: Zone) => z.displayName || t("alarm.zone_display_name", { id: z.id })
    const zoneTooltip = (z: Zone) =>
        `${t("alarm.zone_display_name", { id: z.id })}${!z.displayName ? "" : ": " + z.displayName}.`

    return (
        <Grid key={partition.id} container spacing={1} pb={3}>
            <Grid item xs={12} sm={showDetails ? 6 : 12} md={showDetails ? 4 : 12} lg={showDetails ? 3 : 12}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <PentagonOutlined htmlColor={theme.palette.text.secondary} />
                    <Tooltip title={partitionTooltip(partition)}>
                        <Typography variant="h6" sx={{ alignItems: "center" }}>
                            {partitionLabel(partition)}
                        </Typography>
                    </Tooltip>
                    {partition.isSystem && <SystemTag />}
                    <Box flexGrow={1} />
                    <Tooltip
                        title={t(
                            showDetails
                                ? "alarm.hide_partition_details_tooltip"
                                : "alarm.show_partition_details_tooltip"
                        )}
                        disableInteractive
                    >
                        <IconButton color="primary" onClick={flipDetails}>
                            {showDetails ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Grid>
            {showDetails && <Grid item xs={12} sm={6} md={8} lg={9} />}

            <Grid item xs={12} sm={showDetails ? 6 : 12} md={showDetails ? 4 : 12} lg={showDetails ? 3 : 12}>
                <PartitionInfo
                    armed={armed}
                    alarm={alarm}
                    alarmInMemory={alarmInMemory}
                    disabled={armed ? !allowDisarm : !allowArm}
                    flipArmed={flipArmed}
                />
            </Grid>
            {showDetails && <Grid item xs={12} sm={6} md={8} lg={9} />}

            {showDetails && (
                <>
                    <Grid item xs={12} sm={6}>
                        <SwitchHistoryChart
                            label={t("alarm.arming_history")}
                            siteID={unit.SiteID}
                            partitionID={partition.id}
                            unit={unit}
                            item={"armed"}
                            color={theme.palette.primary.main}
                            currentState={armed}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SwitchHistoryChart
                            label={t("alarm.alarm_history")}
                            siteID={unit.SiteID}
                            partitionID={partition.id}
                            unit={unit}
                            item={"alarm"}
                            color={theme.palette.error.main}
                            showCount={true}
                            currentState={alarm}
                        />
                    </Grid>
                </>
            )}
            {showDetails &&
                filteredZones.length > 0 &&
                filteredZones.map((zone) => (
                    <Grid key={zone.id} item xs={12} sm={6} md={4} lg={3}>
                        <ZoneInfo
                            label={zoneLabel(zone)}
                            tooltip={zoneTooltip(zone)}
                            disabled={!allowBypass || armed}
                            bypassed={zoneState(zone, "bypass")}
                            alarm={zoneState(zone, "alarm")}
                            alarmInMemory={zoneState(zone, "alarm_in_memory")}
                            violation={zoneState(zone, "violation")}
                            lowBattery={zoneState(zone, "low_battery")}
                            noConnection={zoneState(zone, "disconnected")}
                            flipBypass={(on) => flipBypass(on, zone)}
                        />
                    </Grid>
                ))}
        </Grid>
    )
}
