import {
    CameraAlt,
    CellTower,
    Close,
    DoneAll,
    LocationOn,
    MeetingRoom,
    NotificationsActive,
    PentagonOutlined,
} from "@mui/icons-material"
import { Stack, Typography } from "@mui/material"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
    AlarmScope,
    CameraScope,
    GateScope,
    isAllScope,
    PartitionScope,
    Scope,
    SiteScope,
    UnitScope,
} from "../../api/Authz"
import { Site, Unit } from "../../api/Customer"
import { Alarm, Gate } from "../../api/portal/hooks/ScopeThings"
import { SystemTag } from "../newalarm/SystemTag"
import { IconText } from "./IconText"

export type ScopeBoxProps = {
    scope: Scope
    sites: Map<number, Site>
    units: Map<string, Unit>
    gates: Map<string, Gate[]>
    alarms: Map<string, Alarm[]>
}

export function ScopeBox(props: ScopeBoxProps) {
    const { scope, sites, units, gates, alarms } = props

    const { t } = useTranslation()

    const siteUnits = useMemo(
        () =>
            new Map(
                Array.from(sites.values()).map((s) => [
                    s.ID,
                    Array.from(units.values()).filter((u) => u.SiteID === s.ID),
                ])
            ),
        [units, sites]
    )

    const renderScope = () => {
        if (scope.customers?.length !== 1) {
            return <Typography>invalid customer scope, expects singular customer scope</Typography>
        }
        const customer = scope.customers[0]
        if (customer.all) {
            return <Typography>invalid customer scope, matches all customers</Typography>
        }
        return renderSites(customer.sites || [])
    }

    const renderSites = (scope: SiteScope[]) => {
        const allSites = isAllScope(scope)
        const selectedSites = Array.from(sites.values()).filter((site) => scope.some((s) => s.id === "" + site.ID))

        return (
            <Stack p={1} spacing={1}>
                {!allSites && selectedSites.length === 0 && (
                    <IconText icon={<Close />} text={t("field.no_sites")} italic />
                )}
                {allSites ? (
                    <IconText icon={<DoneAll />} text={t("field.all_sites")} italic />
                ) : (
                    selectedSites.map((site) => (
                        <Stack key={site.ID}>
                            <IconText icon={<LocationOn />} bold text={site.DisplayName} />
                            {renderUnits(scope.find((s) => s?.id === "" + site.ID)?.units || [], site)}
                        </Stack>
                    ))
                )}
            </Stack>
        )
    }

    const renderUnits = (scope: UnitScope[], site: Site) => {
        const allUnits = isAllScope(scope)
        const selectedUnits =
            siteUnits.get(site.ID)?.filter((unit) => scope.some((u) => u.shortName === unit.ShortName)) || []

        return (
            <Stack pl={3}>
                {!allUnits && selectedUnits.length === 0 && (
                    <IconText icon={<Close />} text={t("field.no_units")} italic />
                )}
                {allUnits ? (
                    <IconText icon={<DoneAll />} text={t("field.all_units")} italic />
                ) : (
                    selectedUnits.map((unit) => {
                        const unitScope = scope.find((u) => u?.shortName === unit.ShortName)
                        return (
                            <Stack key={unit.ShortName}>
                                <IconText icon={<CellTower />} text={unit.DisplayName} />
                                {renderCameras(unitScope?.cameras || [], unit)}
                                {renderGates(unitScope?.gates || [], unit)}
                                {renderAlarms(unitScope?.alarms || [], unit)}
                            </Stack>
                        )
                    })
                )}
            </Stack>
        )
    }

    const renderCameras = (scope: CameraScope[], unit: Unit) => {
        const allCameras = isAllScope(scope)
        const selectedCameras =
            (unit.UnitConfig.Cameras || []).filter((camera) => scope.some((c) => c.id === camera.ID + "")) || []

        return (
            <Stack pl={3}>
                {!allCameras && selectedCameras.length === 0 && (
                    <IconText icon={<Close />} text={t("field.no_cameras")} italic />
                )}
                {allCameras ? (
                    <IconText icon={<DoneAll />} text={t("field.all_cameras")} italic />
                ) : (
                    selectedCameras.map((camera) => (
                        <IconText
                            key={camera.ID}
                            icon={<CameraAlt />}
                            text={t("camera.nth_camera", { id: camera.ID })}
                        />
                    ))
                )}
            </Stack>
        )
    }

    const renderGates = (scope: GateScope[], unit: Unit) => {
        const allGates = isAllScope(scope)
        const selectedGates =
            (gates.get(unit.ShortName) || []).filter((gate) => scope.some((g) => g.id === gate.id + "")) || []

        return (
            <Stack pl={3}>
                {!allGates && selectedGates.length === 0 && (
                    <IconText icon={<Close />} text={t("field.no_gates")} italic />
                )}
                {allGates ? (
                    <IconText icon={<DoneAll />} text={t("field.all_gates")} italic />
                ) : (
                    selectedGates.map((gate) => (
                        <IconText
                            key={gate.id}
                            icon={<MeetingRoom />}
                            text={gate.displayName || t("unit.gate_display_name", { id: gate.id })}
                        />
                    ))
                )}
            </Stack>
        )
    }

    const renderAlarms = (scope: AlarmScope[], unit: Unit) => {
        const allAlarms = isAllScope(scope)
        const selectedAlarms =
            (alarms.get(unit.ShortName) || []).filter((alarm) => scope.some((a) => a.id === alarm.id + "")) || []

        return (
            <Stack pl={3}>
                {!allAlarms && selectedAlarms.length === 0 && (
                    <IconText icon={<Close />} text={t("field.no_alarms")} italic />
                )}
                {allAlarms ? (
                    <IconText icon={<DoneAll />} text={t("field.all_alarms")} italic />
                ) : (
                    selectedAlarms.map((alarm) => (
                        <Stack key={alarm.id}>
                            <IconText
                                icon={<NotificationsActive />}
                                text={alarm.displayName || t("unit.alarm_display_name", { id: alarm.id })}
                            />
                            {renderPartitions(scope.find((a) => a?.id === alarm.id + "")?.partitions || [], alarm)}
                        </Stack>
                    ))
                )}
            </Stack>
        )
    }

    const renderPartitions = (scope: PartitionScope[], alarm: Alarm) => {
        const allPartitions = isAllScope(scope)
        const selectedPartitions =
            (alarm.partitions || []).filter((partition) => scope.some((p) => p.id === partition.id + "")) || []

        return (
            <Stack pl={3}>
                {!allPartitions && selectedPartitions.length === 0 && (
                    <IconText icon={<Close />} text={t("field.no_partitions")} italic />
                )}
                {allPartitions ? (
                    <IconText icon={<DoneAll />} text={t("field.all_partitions")} italic />
                ) : (
                    selectedPartitions.map((partition) => (
                        <IconText
                            key={partition.id}
                            icon={<PentagonOutlined />}
                            text={partition.displayName || t("alarm.partition_display_name", { id: partition.id })}
                            chip={partition.system ? <SystemTag small /> : undefined}
                        />
                    ))
                )}
            </Stack>
        )
    }

    return renderScope()
}
