import { ProviderContext } from "notistack"
import { rawHttp } from "../backend/request"
import { request } from "../config/headers"
import { endpointURL } from "../config/urls"
import { Unit } from "./Customer"

export interface Item {
    name: string
    label: string
    state: string
    groupNames: string[]
    tags: string[]
}

export interface DataPoint {
    time: number
    state: string
}

export interface DataPoints {
    name: string
    data: DataPoint[]
}

export interface Event {
    topic: string
    type: string
    payload: string
}

export interface Payload {
    value: string
    oldValue: string
}

export const StateNULL = "NULL"
export const StateON = "ON"
export const StateOFF = "OFF"

export const unitAlarm = (unit: Unit) => `alarm/units/${unit.ID}/armed`
export const unitSystemAlarm = (unit: Unit) => `alarm/units/${unit.ID}/system-armed`
export const unitAutotArmLock = (unit: Unit) => `alarm/units/${unit.ID}/auto-arm-lock`
export const unitZoneBypass = (unit: Unit, zone: number) => `alarm/units/${unit.ID}/zones/${zone}/bypass`
export const unitGateOpen = (unit: Unit, gate: number) => `access/units/${unit.ID}/gates/${gate}/open`
export const unitLight = (unit: Unit) => `units/${unit.ID}/state/light`

export const revertSwitchState = (value: string) => (value === StateOFF ? StateON : StateOFF)
const portalStateMap = new Map([
    [StateON, "on"],
    [StateOFF, "off"],
])
const toPortalState = (s: string) => portalStateMap.get(s) || "undefined"

export const flipUnitSwitch = ({ name, state }: { name: string; state: string }, path: string, snackbar: ProviderContext) => {
    const flipped = toPortalState(revertSwitchState(state))
    rawHttp(`Setting item ${name} to ${flipped}`, endpointURL(path), snackbar, {
        method: "PUT",
        headers: request.headers,
        body: JSON.stringify(flipped),
    })
        .then((_) => console.log("Successfuly set item", name, flipped))
        .catch((e) => console.log(e))
}
