import { Box, Grid, Stack, Typography, useTheme } from "@mui/material"
import { useSnackbar } from "notistack"
import { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Unit } from "../../api/Customer"
import { Item, Payload } from "../../api/OpenHAB"
import { OpenHABStream } from "../../api/OpenHABStream"
import { http } from "../../backend/request"
import { openHabEndpointURL } from "../../config/urls"
import { Channel, MultiChart, Ruler } from "../common/MultiChart"
import { OneDayRange, RangeChoice } from "../common/RangeChoice"

const itemExists = (item?: Item) => !!item
const itemValue = (item?: Item) =>
    !item || item.state === "NULL" || item.state === "UNDEF" ? "?" : (+item.state).toFixed(2)

export interface StatisticsProps {
    unit: Unit
}

const relevantItems = new Set([
    "PRO380_ActivePower_Total",
    "EPEVER_3102_number",
    "EPEVER2_3102_number",
    "EPEVER3_3102_number",
    "EPEVER4_3102_number",
    "BatVoltageMAX",
    "BatSOC",
    "Battery",
    "LoadPowerSUM",
    "routerSignal",
])
const isRelevant = (name: string) => relevantItems.has(name)
const parseAnnotations = (label: string) =>
    new Map(
        label
            .split(",")
            .map((s) => s.split(":", 2))
            .filter((a) => a.length === 2)
            .map((a) => [a[0], a[1]])
    )

export function Statistics(props: StatisticsProps) {
    const { unit } = props

    const [items, setItems] = useState<Item[]>([])
    const [range, setRange] = useState(OneDayRange)

    const snackbar = useSnackbar()
    const theme = useTheme()
    const { t } = useTranslation()

    const powerLines = useMemo(
        () => [
            {
                name: "PRO380_ActivePower_Total",
                label: t("statistics.power_line", { idx: 1 }),
                color: theme.palette.primary.main,
            },
        ],
        [t, theme]
    )
    const solarPanels = useMemo(
        () => [
            {
                name: "EPEVER_3102_number",
                label: t("statistics.solar_panel", { idx: 1 }),
                color: "rgb(255,200,50)",
            },
            {
                name: "EPEVER2_3102_number",
                label: t("statistics.solar_panel", { idx: 2 }),
                color: "rgb(255,80,0)",
            },
            {
                name: "EPEVER3_3102_number",
                label: t("statistics.solar_panel", { idx: 3 }),
                color: "rgb(255,40,10)",
            },
            {
                name: "EPEVER4_3102_number",
                label: t("statistics.solar_panel", { idx: 4 }),
                color: "rgb(255,160,40)",
            },
        ],
        [t]
    )
    const batteryPercents = useMemo(
        () => [
            {
                name: "BatSOC",
                label: t("statistics.battery", { idx: 1 }),
                color: "#09c809",
            },
        ],
        [t]
    )
    const batteryVoltages = useMemo(
        () => [
            {
                name: "BatVoltageMAX",
                label: t("statistics.battery", { idx: 1 }),
                color: "#09c809",
            },
        ],
        [t]
    )
    const loads = useMemo(
        () => [
            {
                name: "LoadPowerSUM",
                label: t("statistics.load", { idx: "" }),
                color: "rgb(255,50,20)",
            },
        ],
        [t]
    )
    const signalStrengths = useMemo(
        () => [
            {
                name: "routerSignal",
                label: t("statistics.signal_strength"),
                color: theme.palette.primary.main,
            },
        ],
        [t, theme]
    )

    const reloadItems = useCallback(() => {
        http<Item[]>("Getting unit items", openHabEndpointURL(unit, `items`), snackbar)
            .then((result) =>
                setItems(result.filter((item) => isRelevant(item.name)).sort((a, b) => (a.name > b.name ? 1 : -1)))
            )
            .catch((e) => console.log(e))
    }, [snackbar, unit])

    const onOpenHABMessage = useCallback((name: string, payload: Payload) => {
        console.log("changing item", name, "to", payload.value)
        setItems((old) =>
            old.map((item) =>
                item.name === name
                    ? {
                          name: item.name,
                          groupNames: item.groupNames,
                          tags: item.tags,
                          label: item.label,
                          state: payload.value,
                      }
                    : item
            )
        )
    }, [])

    const onOpenHABConnected = useCallback(() => {
        console.log("stream connected")
        reloadItems()
    }, [reloadItems])

    const activePower = useMemo(() => items.find((item) => item.name === "PRO380_ActivePower_Total"), [items])
    const solar1 = useMemo(() => items.find((item) => item.name === "EPEVER_3102_number"), [items])
    const solar2 = useMemo(() => items.find((item) => item.name === "EPEVER2_3102_number"), [items])
    const solar3 = useMemo(() => items.find((item) => item.name === "EPEVER3_3102_number"), [items])
    const solar4 = useMemo(() => items.find((item) => item.name === "EPEVER4_3102_number"), [items])
    const batteryVoltage = useMemo(() => items.find((item) => item.name === "BatVoltageMAX"), [items])
    const batteryPercent = useMemo(() => items.find((item) => item.name === "BatSOC"), [items])
    const batteryInfo = useMemo(
        () => parseAnnotations(items.find((item) => item.name === "Battery")?.label || ""),
        [items]
    )
    const batteryTechnology = useMemo(() => batteryInfo.get("Technology"), [batteryInfo])
    const batterLevels = useMemo(() => {
        const levels = []
        const full = batteryInfo.get("VoltageFull")
        if (full) {
            levels.push({
                label: t("statistics.battery_full"),
                value: +full,
                color: "green",
            })
        }
        const low = batteryInfo.get("VoltageLow")
        if (low) {
            levels.push({
                label: t("statistics.battery_low"),
                value: +low,
                color: "orange",
            })
        }
        const off = batteryInfo.get("VoltageOff")
        if (off) {
            levels.push({
                label: t("statistics.battery_off"),
                value: +off,
                color: "red",
            })
        }
        return levels
    }, [batteryInfo, t])
    const load = useMemo(() => items.find((item) => item.name === "LoadPowerSUM"), [items])
    const signalStrength = useMemo(() => items.find((item) => item.name === "routerSignal"), [items])

    const renderValue = (label: string, item: Item | undefined, valueUnit: string, color?: string, note?: string) =>
        itemExists(item) ? (
            <Grid item xs={12} sm={6} md={3} lg={2}>
                <Stack sx={{ backgroundColor: theme.palette.background.paper }} p={1}>
                    <Typography>
                        {label} {note ? "(" + note + ")" : ""}
                    </Typography>
                    <Typography color={color || theme.palette.primary.main}>
                        {itemValue(item)} {valueUnit}
                    </Typography>
                </Stack>
            </Grid>
        ) : null

    const signalQualityText = (value: string) => {
        const n = +value
        if (n === 0) return t("filed.unknown")
        if (n >= -65) return t("statistics.signal_excellent")
        if (n >= -75) return t("statistics.signal_good")
        if (n >= -85) return t("statistics.signal_fair")
        if (n >= -95) return t("statistics.signal_poor")
        return t("statistics.signal_none")
    }

    const signalQualityColor = (value: string) => {
        const n = +value
        if (n === 0) return theme.palette.error.main
        if (n >= -65) return theme.palette.success.main
        if (n >= -75) return "#d0c637"
        if (n >= -85) return theme.palette.warning.light
        if (n >= -95) return theme.palette.warning.dark
        return theme.palette.error.main
    }

    const renderSignalValue = (item: Item | undefined) =>
        !!item ? (
            <Grid item xs={12} sm={6} md={3} lg={2}>
                <Stack sx={{ backgroundColor: theme.palette.background.paper }} p={1}>
                    <Typography>{t("statistics.signal_strength")}</Typography>
                    <Typography color={signalQualityColor(item.state)}>
                        {signalQualityText(item.state)}: {itemValue(item)} dB
                    </Typography>
                </Stack>
            </Grid>
        ) : null

    const renderMultiChart = (
        lines: Channel[],
        valueUnit: string,
        rulers?: Ruler[],
        startValue?: number,
        endValue?: number
    ) => (
        <Grid item xs={12}>
            <Box
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    width: "100%",
                    height: {
                        xs: "150px",
                        sm: "200px",
                        md: "250px",
                        lg: "300px",
                    },
                }}
                p={1}
            >
                <MultiChart
                    unit={unit}
                    items={lines}
                    millis={range.millis}
                    valueUnit={valueUnit}
                    startValue={startValue}
                    endValue={endValue}
                    rulers={rulers}
                />
            </Box>
        </Grid>
    )

    return (
        <Stack sx={{ p: 4, pt: 2, alignItems: "center", width: "100%" }}>
            <Box sx={{ width: "min(100%,1280px)" }}>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Box flexGrow={1}>
                        <OpenHABStream
                            unit={unit}
                            itemFilter={isRelevant}
                            onMessage={onOpenHABMessage}
                            onConnected={onOpenHABConnected}
                        />
                    </Box>
                    <RangeChoice value={range} onChange={setRange} />
                </Stack>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h5">{t("statistics.power_output")}</Typography>
                    </Grid>
                    {itemExists(activePower) ? (
                        <>
                            {renderValue(
                                t("statistics.power_line", { idx: 1 }),
                                activePower,
                                "kW",
                                powerLines[0].color
                            )}
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        backgroundColor: theme.palette.background.paper,
                                        width: "100%",
                                        height: {
                                            xs: "150px",
                                            sm: "200px",
                                            md: "250px",
                                            lg: "300px",
                                        },
                                    }}
                                    p={1}
                                >
                                    <MultiChart
                                        unit={unit}
                                        items={powerLines}
                                        millis={range.millis}
                                        valueUnit="kW"
                                        startValue={0}
                                    />
                                </Box>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12}>
                            <Typography variant="body1" color={theme.palette.text.secondary} fontStyle="italic">
                                {t("statistics.no_power_statistics")}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Typography variant="h5">{t("statistics.solar_panels")}</Typography>
                    </Grid>
                    {itemExists(solar1) || itemExists(solar2) || itemExists(solar3) || itemExists(solar4) ? (
                        <>
                            {renderValue(t("statistics.solar_panel", { idx: 1 }), solar1, "W", solarPanels[0].color)}
                            {renderValue(t("statistics.solar_panel", { idx: 2 }), solar2, "W", solarPanels[1].color)}
                            {renderValue(t("statistics.solar_panel", { idx: 3 }), solar3, "W", solarPanels[2].color)}
                            {renderValue(t("statistics.solar_panel", { idx: 4 }), solar4, "W", solarPanels[3].color)}
                            {renderMultiChart(solarPanels, "W", undefined, 0)}
                        </>
                    ) : (
                        <Grid item xs={12}>
                            <Typography variant="body1" color={theme.palette.text.secondary} fontStyle="italic">
                                {t("statistics.no_solar_statistics")}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Typography variant="h5">{t("statistics.batteries")}</Typography>
                    </Grid>
                    {itemExists(batteryPercent) || itemExists(batteryVoltage) ? (
                        <>
                            {itemExists(batteryPercent) && (
                                <>
                                    {renderValue(
                                        t("statistics.battery", { idx: 1 }),
                                        batteryPercent,
                                        "%",
                                        batteryPercents[0].color,
                                        batteryTechnology
                                    )}
                                    {renderMultiChart(batteryPercents, "%", undefined, 0, 100)}
                                </>
                            )}
                            {itemExists(batteryVoltage) && (
                                <>
                                    {renderValue(
                                        t("statistics.battery", { idx: 1 }),
                                        batteryVoltage,
                                        "V",
                                        batteryVoltages[0].color,
                                        batteryTechnology
                                    )}
                                    {renderMultiChart(batteryVoltages, "V", batterLevels)}
                                </>
                            )}
                        </>
                    ) : (
                        <Grid item xs={12}>
                            <Typography variant="body1" color={theme.palette.text.secondary} fontStyle="italic">
                                {t("statistics.no_battery_statistics")}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Typography variant="h5">{t("statistics.loads")}</Typography>
                    </Grid>
                    {itemExists(load) ? (
                        <>
                            {renderValue(t("statistics.load", { idx: "" }), load, "W", loads[0].color)}
                            {renderMultiChart(loads, "W")}
                        </>
                    ) : (
                        <Grid item xs={12}>
                            <Typography variant="body1" color={theme.palette.text.secondary} fontStyle="italic">
                                {t("statistics.no_load_statistics")}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Typography variant="h5">{t("statistics.signals")}</Typography>
                    </Grid>
                    {!!signalStrength ? (
                        <>
                            {renderSignalValue(signalStrength)}
                            {renderMultiChart(signalStrengths, "dB", [
                                {
                                    label: t("statistics.signal_excellent"),
                                    value: -65,
                                    color: theme.palette.success.main,
                                },
                                {
                                    label: t("statistics.signal_good"),
                                    value: -75,
                                    color: "#d0c637",
                                },
                                {
                                    label: t("statistics.signal_fair"),
                                    value: -85,
                                    color: theme.palette.warning.light,
                                },
                                {
                                    label: t("statistics.signal_poor"),
                                    value: -95,
                                    color: theme.palette.warning.dark,
                                },
                                {
                                    label: t("statistics.signal_none"),
                                    value: -105,
                                    color: theme.palette.error.main,
                                    labelOnly: true,
                                },
                            ])}
                        </>
                    ) : (
                        <Grid item xs={12}>
                            <Typography variant="body1" color={theme.palette.text.secondary} fontStyle="italic">
                                {t("statistics.no_signal_statistics")}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Stack>
    )
}
