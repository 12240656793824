import { Stack, Typography, useTheme } from "@mui/material"
import { cloneElement, ReactElement } from "react"

export interface IconTextProps {
    icon: ReactElement
    italic?: boolean
    bold?: boolean
    text: string
    chip?: ReactElement
}

export const IconText = (props: IconTextProps) => {
    const { icon, italic, bold, text, chip } = props

    const theme = useTheme()

    return (
        <Stack direction="row" spacing={1} pr={1}>
            {cloneElement(icon, {
                fontSize: "small",
                htmlColor: theme.palette.grey[600],
            })}
            <Typography
                variant="body2"
                fontWeight={bold ? "bold" : undefined}
                fontStyle={italic ? "italic" : undefined}
                noWrap
            >
                {text}
            </Typography>
            {chip}
        </Stack>
    )
}
