import { Box, Grid, Stack, Typography, useTheme } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Site, Unit } from "../../api/Customer"
import { OneDayRange, RangeChoice } from "../common/RangeChoice"
import { NewMultiChart, Ruler } from "../common/NewMultiChart"
import { useIoT, useIoTValues } from "../../services/IoT"
import { ConnectionStatus } from "../common/ConnectionStatus"

export interface StatisticsProps {
    unit: Unit
    site: Site
}

const classes = ["solar_panel", "battery", "load", "router"]
const redColors = ["C7253E", "#C40C0C", "#FF6500", "#FF8A08", "#FFC100"]
const greenColors = ["#16FF00", "#00FFAB", "#B8F1B0", "#9CFF2E", "#38E54D"]
const blueColors = ["#A7E6FF", "#3ABEF9", "#3572EF", "#050C9C"]

const getRulers = (
    dataMap: Map<string, string>,
    levels: { name: string; label: string; color: string; labelOnly?: boolean }[]
): Ruler[] => {
    const rulers: Ruler[] = []
    levels.forEach((level) => {
        const value = dataMap.get(level.name)
        if (value) {
            rulers.push({
                label: level.label,
                value: +value,
                color: level.color,
                labelOnly: level.labelOnly,
            })
        }
    })
    return rulers
}

export function NewStatistics(props: StatisticsProps) {
    const { unit, site } = props

    const { t } = useTranslation()
    const theme = useTheme()

    const { online, clients, items, values } = useIoT(site, unit.ShortName, true, classes)
    const solarPanelPowers = useIoTValues("solar_panel", "power", clients, items, values)
    const batteryPercentages = useIoTValues("battery", "percentage", clients, items, values)
    const batteryVoltages = useIoTValues("battery", "voltage", clients, items, values)
    const loadPower = useIoTValues("load", "power", clients, items, values)
    const routerSignals = useIoTValues("router", "signal_strength", clients, items, values)
    const [range, setRange] = useState(OneDayRange)

    const batteryVoltageRulers = (batteryInfo: Map<string, string> | undefined) => {
        if (batteryInfo === undefined) return

        const voltageLevels = [
            {
                name: "voltage_full",
                label: t("statistics.battery_full"),
                color: "green",
            },
            {
                name: "voltage_low",
                label: t("statistics.battery_low"),
                color: "orange",
            },
            {
                name: "voltage_off",
                label: t("statistics.battery_off"),
                color: "red",
            },
        ]

        return getRulers(batteryInfo, voltageLevels)
    }

    const routerSignalRulers = (routerInfo: Map<string, string> | undefined) => {
        if (routerInfo === undefined) return

        const routerLevels = [
            {
                name: "signal_excellent",
                label: t("statistics.signal_excellent"),
                color: "green",
            },
            {
                name: "signal_good",
                label: t("statistics.signal_good"),
                color: "yellow",
            },
            {
                name: "signal_fair",
                label: t("statistics.signal_fair"),
                color: "gold",
            },
            {
                name: "signal_poor",
                label: t("statistics.signal_poor"),
                color: "orange",
            },
            {
                name: "signal_none",
                label: t("statistics.signal_none"),
                color: "red",
                labelOnly: true,
            },
        ]
        return getRulers(routerInfo, routerLevels)
    }

    const createLabelForBattery = (id: number, annotations?: Map<string, string>) => {
        const technology = annotations?.get("technology")
        const name = t("statistics.battery", { idx: id })
        return technology ? `${name} (${technology})` : name
    }

    const chartTitle = (title: string) => (
        <Grid item xs={12}>
            <Typography variant="h5">{title}</Typography>
        </Grid>
    )

    const noStatisticsMessage = (message: string) => (
        <Grid item xs={12}>
            <Typography variant="body1" color={theme.palette.text.secondary} fontStyle="italic">
                {message}
            </Typography>
        </Grid>
    )

    return (
        <Stack sx={{ p: 4, pt: 2, alignItems: "center", width: "100%" }}>
            <Box sx={{ width: "min(100%,1280px)" }}>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Box flexGrow={1}>
                        <ConnectionStatus online={online} />
                    </Box>
                    <RangeChoice value={range} onChange={setRange} />
                </Stack>
                <Grid container spacing={1}>
                    {chartTitle(t("statistics.solar_panels"))}
                    {solarPanelPowers.length ? (
                        <NewMultiChart
                            siteID={site.ID}
                            unit={unit}
                            itemValues={solarPanelPowers}
                            label={(id) => t("statistics.solar_panel", { idx: id })}
                            timeOffsetMS={range.millis}
                            valueUnit=" W"
                            colorPalette={redColors}
                        />
                    ) : (
                        noStatisticsMessage(t("statistics.no_solar_statistics"))
                    )}

                    {chartTitle(t("statistics.batteries"))}
                    {!batteryPercentages.length &&
                        !batteryVoltages.length &&
                        noStatisticsMessage(t("statistics.no_battery_statistics"))}

                    {!!batteryPercentages.length && (
                        <NewMultiChart
                            siteID={site.ID}
                            unit={unit}
                            itemValues={batteryPercentages}
                            label={createLabelForBattery}
                            timeOffsetMS={range.millis}
                            valueUnit="%"
                            colorPalette={greenColors}
                            startValue={0}
                            endValue={100}
                        />
                    )}

                    {batteryVoltages.map((battery) => (
                        <NewMultiChart
                            key={battery.thing}
                            siteID={site.ID}
                            unit={unit}
                            itemValues={[battery]}
                            label={createLabelForBattery}
                            timeOffsetMS={range.millis}
                            valueUnit=" V"
                            rulers={batteryVoltageRulers(battery.annotations)}
                            colorPalette={greenColors}
                        />
                    ))}

                    {chartTitle(t("statistics.loads"))}
                    {loadPower.length ? (
                        <NewMultiChart
                            siteID={site.ID}
                            unit={unit}
                            itemValues={loadPower}
                            label={(id) => t("statistics.load", { idx: id })}
                            timeOffsetMS={range.millis}
                            valueUnit=" W"
                            colorPalette={redColors}
                        />
                    ) : (
                        noStatisticsMessage(t("statistics.no_load_statistics"))
                    )}

                    {chartTitle(t("statistics.signals"))}
                    {routerSignals.length
                        ? routerSignals.map((router) => (
                              <NewMultiChart
                                  siteID={site.ID}
                                  unit={unit}
                                  itemValues={[router]}
                                  label={(id) => t("statistics.signal", { idx: id })}
                                  timeOffsetMS={range.millis}
                                  valueUnit=" dB"
                                  rulers={routerSignalRulers(router.annotations)}
                                  colorPalette={blueColors}
                              />
                          ))
                        : noStatisticsMessage(t("statistics.no_signal_statistics"))}
                </Grid>
            </Box>
        </Stack>
    )
}
