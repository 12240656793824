import { Box, FormControlLabel, Grid, Stack, Switch, Typography, useTheme } from "@mui/material"
import { useSnackbar } from "notistack"
import { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Operation } from "../../api/Authz"
import { Unit } from "../../api/Customer"
import {
    flipUnitSwitch,
    Item,
    Payload,
    StateON,
    unitAlarm,
    unitAutotArmLock,
    unitLight,
    unitSystemAlarm,
    unitZoneBypass,
} from "../../api/OpenHAB"
import { OpenHABStream } from "../../api/OpenHABStream"
import { useUnitPerimeterPermission, useUnitPermission, useUnitSystemPermission } from "../../auth/AuthorizerProvider"
import { http } from "../../backend/request"
import { openHabEndpointURL } from "../../config/urls"
import { LightSwitch } from "../common/LightSwitch"
import { PartitionInfo } from "./PartitionInfo"
import { SwitchHistoryChart } from "./SwitchHistoryChart"
import { ZoneInfo } from "./ZoneInfo"

const getZone = (name: string) => name.substring(0, 6)
const getZoneBypassID = (name: string) => {
    const found = name.match(/^ZONE(\d+)_BYPASS$/)
    if (!found) {
        return 0
    }
    return +found[1]
}

const relevantZones = new Set([
    "PARTITION_ARMED",
    "PARTITION_ALARM",
    "PARTITION_ALARM_M",
    "UNIT_PARTITION_ARMED",
    "UNIT_PARTITION_ALARM",
    "UNIT_PARTITION_ALARM_M",
    "AUTOARMLOCK",
    "LIGHTSWITCH",
])
const isRelevant = (name: string) => name.startsWith("ZONE") || relevantZones.has(name)

export interface AlarmProps {
    unit: Unit
}

export function Alarm(props: AlarmProps) {
    const { unit } = props

    const [items, setItems] = useState<Item[]>([])

    const snackbar = useSnackbar()
    const theme = useTheme()
    const { t } = useTranslation()

    const allowArmPartition = useUnitPerimeterPermission(Operation.ARM_PARTITION, unit)
    const allowDisarmPartition = useUnitPerimeterPermission(Operation.DISARM_PARTITION, unit)
    const allowBypassZone = useUnitPerimeterPermission(Operation.BYPASS_ZONE, unit)
    const allowBypassSystemZone = useUnitPerimeterPermission(Operation.BYPASS_SYSTEM_ZONE, unit)
    const allowArmSystemPartition = useUnitSystemPermission(Operation.ARM_SYSTEM_PARTITION, unit)
    const allowDisarmSystemPartition = useUnitSystemPermission(Operation.DISARM_SYSTEM_PARTITION, unit)
    const allowSwitchAutoArm = useUnitPermission(Operation.SWITCH_AUTO_ARM, unit)
    const allowSwitchLight = useUnitPermission(Operation.SWITCH_LIGHT, unit)

    const reloadItems = useCallback(() => {
        http<Item[]>("Getting unit items", openHabEndpointURL(unit, `items`), snackbar)
            .then((result) =>
                setItems(result.filter((item) => isRelevant(item.name)).sort((a, b) => (a.name > b.name ? 1 : -1)))
            )
            .catch((e) => console.log(e))
    }, [snackbar, unit])

    const onOpenHABMessage = useCallback((name: string, payload: Payload) => {
        console.log("changing item", name, "to", payload.value)
        setItems((old) =>
            old.map((item) =>
                item.name === name
                    ? {
                          name: item.name,
                          groupNames: item.groupNames,
                          tags: item.tags,
                          label: item.label,
                          state: payload.value,
                      }
                    : item
            )
        )
    }, [])

    const onOpenHABConnected = useCallback(() => {
        console.log("stream connected")
        reloadItems()
    }, [reloadItems])

    const perimeterArmed = useMemo(() => items.find((item) => item.name === "PARTITION_ARMED"), [items])
    const perimeterAlarm = useMemo(() => items.find((item) => item.name === "PARTITION_ALARM"), [items])
    const perimeterMemory = useMemo(() => items.find((item) => item.name === "PARTITION_ALARM_M"), [items])
    const systemArmed = useMemo(() => items.find((item) => item.name === "UNIT_PARTITION_ARMED"), [items])
    const systemAlarm = useMemo(() => items.find((item) => item.name === "UNIT_PARTITION_ALARM"), [items])
    const systemMemory = useMemo(() => items.find((item) => item.name === "UNIT_PARTITION_ALARM_M"), [items])
    const autoArmLock = useMemo(() => items.find((item) => item.name === "AUTOARMLOCK"), [items])
    const lightSwitch = useMemo(() => items.find((item) => item.name === "LIGHTSWITCH"), [items])
    const zoneItems = useMemo(() => items.filter((item) => item.name.startsWith("ZONE")), [items])
    const mapToStateOnWithSuffix = useCallback(
        (suffix: string) =>
            new Set(
                zoneItems
                    .filter((item) => item.name?.endsWith(suffix) && item.state === StateON)
                    .map((item) => getZone(item.name))
            ),
        [zoneItems]
    )
    const bypasses = useMemo(
        () => zoneItems.filter((item) => item.name?.endsWith("_BYPASS") && item.groupNames.includes("BypassPer")),
        [zoneItems]
    )
    const alarms = useMemo(() => mapToStateOnWithSuffix("_ALARM"), [mapToStateOnWithSuffix])
    const memoryAlarms = useMemo(() => mapToStateOnWithSuffix("_ALARM_MEM"), [mapToStateOnWithSuffix])
    const violations = useMemo(() => mapToStateOnWithSuffix("_VIOLATION"), [mapToStateOnWithSuffix])
    const lowBatteries = useMemo(() => mapToStateOnWithSuffix("_BAT"), [mapToStateOnWithSuffix])
    const noConnection = useMemo(() => mapToStateOnWithSuffix("_NOCOM"), [mapToStateOnWithSuffix])

    const isActive = (item: Item, active: Set<string>) => active.has(getZone(item.name))
    const hasViolation = (item: Item) => isActive(item, violations)
    const hasAlarm = (item: Item) => isActive(item, alarms)
    const hasMemoryAlarm = (item: Item) => isActive(item, memoryAlarms)
    const hasLowBattery = (item: Item) => isActive(item, lowBatteries)
    const hasNoConnection = (item: Item) => isActive(item, noConnection)

    const flipSwitch = useCallback((item: Item, path: string) => flipUnitSwitch(item, path, snackbar), [snackbar])

    return (
        <Stack sx={{ py: 2, px: 4, alignItems: "center", width: "100%" }}>
            <Box sx={{ width: "min(100%,1280px)" }}>
                <OpenHABStream
                    unit={unit}
                    itemFilter={isRelevant}
                    onMessage={onOpenHABMessage}
                    onConnected={onOpenHABConnected}
                />
                <Grid container spacing={1}>
                    {(perimeterArmed || systemArmed || autoArmLock) && (
                        <>
                            <Grid item xs={12}>
                                <Stack direction="row" sx={{ alignItems: "center" }} spacing={1}>
                                    <Typography variant="h5" sx={{ flexGrow: 1 }}>
                                        {t("offering.alarm")}
                                    </Typography>
                                </Stack>
                            </Grid>
                            {perimeterArmed && (
                                <Grid key={perimeterArmed.name} item xs={12} sm={4} lg={2}>
                                    <PartitionInfo
                                        label={t("alarm.perimeter")}
                                        arm={perimeterArmed}
                                        disabled={
                                            perimeterArmed.state === StateON
                                                ? !allowDisarmPartition
                                                : !allowArmPartition
                                        }
                                        alarm={perimeterAlarm?.state === StateON}
                                        memoryAlarm={perimeterMemory?.state === StateON}
                                        flipSwitch={(i) => flipSwitch(i, unitAlarm(unit))}
                                    />
                                </Grid>
                            )}
                            {perimeterArmed && (
                                <Grid key={perimeterArmed.name + "-history"} item xs={12} sm={4} lg={5}>
                                    <SwitchHistoryChart
                                        label={t("alarm.arming_history")}
                                        unit={unit}
                                        item={perimeterArmed.name}
                                        color={theme.palette.primary.main}
                                    />
                                </Grid>
                            )}
                            {perimeterAlarm && (
                                <Grid key={perimeterAlarm.name} item xs={12} sm={4} lg={5}>
                                    <SwitchHistoryChart
                                        label={t("alarm.alarm_history")}
                                        unit={unit}
                                        item={perimeterAlarm.name}
                                        color={theme.palette.error.main}
                                        showCount={true}
                                    />
                                </Grid>
                            )}
                            {systemArmed && (
                                <Grid key={systemArmed.name} item xs={12} sm={4} lg={2}>
                                    <PartitionInfo
                                        label={t("alarm.system")}
                                        arm={systemArmed}
                                        disabled={
                                            systemArmed.state === StateON
                                                ? !allowDisarmSystemPartition
                                                : !allowArmSystemPartition
                                        }
                                        alarm={systemAlarm?.state === StateON}
                                        memoryAlarm={systemMemory?.state === StateON}
                                        flipSwitch={(i) => flipSwitch(i, unitSystemAlarm(unit))}
                                    />
                                </Grid>
                            )}
                            {systemArmed && (
                                <Grid key={systemArmed.name + "-history"} item xs={12} sm={4} lg={5}>
                                    <SwitchHistoryChart
                                        label={t("alarm.arming_history")}
                                        unit={unit}
                                        item={systemArmed.name}
                                        color={theme.palette.primary.main}
                                    />
                                </Grid>
                            )}
                            {systemAlarm && (
                                <Grid key={systemAlarm.name} item xs={12} sm={4} lg={5}>
                                    <SwitchHistoryChart
                                        label={t("alarm.alarm_history")}
                                        unit={unit}
                                        item={systemAlarm.name}
                                        color={theme.palette.error.main}
                                        showCount={true}
                                    />
                                </Grid>
                            )}
                            {autoArmLock && (
                                <Grid key={autoArmLock.name} item xs={12}>
                                    <Stack
                                        direction="row"
                                        sx={{
                                            backgroundColor: theme.palette.background.paper,
                                            alignItems: "center",
                                        }}
                                        px={1}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={autoArmLock.state === StateON}
                                                    disabled={!allowSwitchAutoArm}
                                                    onClick={() => flipSwitch(autoArmLock, unitAutotArmLock(unit))}
                                                />
                                            }
                                            label={t("alarm.auto_arm_lock")}
                                        />
                                    </Stack>
                                </Grid>
                            )}
                        </>
                    )}
                    {bypasses.length > 0 && (
                        <Grid item xs={12}>
                            <Typography variant="h6">{t("alarm.zone_bypass")}</Typography>
                        </Grid>
                    )}
                    {bypasses.map((item) => (
                        <Grid key={item.name} item xs={12} sm={6} md={4} lg={3}>
                            <ZoneInfo
                                bypass={item}
                                disabled={
                                    item.groupNames.includes("BypassPer")
                                        ? perimeterArmed?.state === StateON || !allowBypassZone
                                        : systemArmed?.state === StateON || !allowBypassSystemZone
                                }
                                alarm={hasAlarm(item)}
                                memoryAlarm={hasMemoryAlarm(item)}
                                violation={hasViolation(item)}
                                lowBattery={hasLowBattery(item)}
                                noConnection={hasNoConnection(item)}
                                flipSwitch={(i) => flipSwitch(i, unitZoneBypass(unit, getZoneBypassID(i.name)))}
                            />
                        </Grid>
                    ))}
                    {lightSwitch && [
                        <Grid key="light-header" item xs={12}>
                            <Typography variant="h6">{t("unit.lights")}</Typography>
                        </Grid>,
                        <Grid key="light-swicth" item xs={12}>
                            <LightSwitch
                                light={lightSwitch}
                                disabled={!allowSwitchLight}
                                flipSwitch={(i) => flipSwitch(i, unitLight(unit))}
                            />
                        </Grid>,
                    ]}
                </Grid>
            </Box>
        </Stack>
    )
}
