import { http, noSnackBar } from "../backend/request"
import { request } from "../config/headers"
import { monoEndpointURL } from "../config/urls"
import { Site } from "./Customer"

export interface SendCommandRequest {
    command: ItemCommand
}

export interface ItemCommand {
    thing: string
    item: string
    command: Command
}

export interface Command {
    number: number
}

export const SendCommand = (site: Site, item: string, value: number, thing: string) => {
    const command: SendCommandRequest = {
        command: {
            thing: thing,
            item: item,
            command: {
                number: value,
            },
        },
    }
    http(`Sending command`, monoEndpointURL(`sites/${site.ID}/commands`), noSnackBar, {
        method: "POST",
        headers: request.headers,
        body: JSON.stringify(command),
    })
        .then((_) => console.log("Successfuly set item"))
        .catch((e) => console.log(e))
}
