import { createApi, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react"
import { request } from "../../config/headers"
import { monoEndpointURL } from "../../config/urls"
import { Customer, Site } from "../Customer"

export interface Thing {
    name: string
    manifest: ThingManifest
}

export interface ThingManifest {
    labels?: WellKnownLabels
    annotations?: WellKnownAnnotations
}

export interface WellKnownLabels {
    class?: string
    unit?: string
    alarm?: string
    member_system?: string
}

export interface WellKnownAnnotations {
    display_name?: string
}

interface ListThingsResponse {
    data?: Thing[]
}

interface customerThings {
    customer: Customer
    classes: string[]
}

const query = (site: Site, classes: string[]) => `sites/${site.ID}/things` + (classes.length > 0 ? "?label=class:" + classes.join(",") : "")

export const thingsApi = createApi({
    reducerPath: "thingsApi",
    baseQuery: fetchBaseQuery({ ...request, baseUrl: monoEndpointURL("") }),
    tagTypes: ["Things"],
    endpoints: (builder) => ({
        getCustomerThings: builder.query<Thing[], customerThings>({
            async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
                const result = await Promise.all(
                    arg.customer.Sites
                        .filter(s => !s.Archived)
                        .map(s => fetchWithBQ(query(s, arg.classes)))
                )
                const error = result.find(r => r.error)?.error
                if (error) {
                    return { error: error as FetchBaseQueryError }
                }
                return {
                    data: result.flatMap(r => (r.data as ListThingsResponse).data || [])
                }
            },
            providesTags: [{ type: "Things", id: "LIST" }],
        }),
    }),
})

export const { useGetCustomerThingsQuery } = thingsApi
