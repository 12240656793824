import {
    CameraAlt,
    CellTower,
    Close,
    DoneAll,
    LocationOn,
    MeetingRoom,
    NotificationsActive,
    PentagonOutlined,
} from "@mui/icons-material"
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { Fragment, ReactElement, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
    AlarmScope,
    CameraScope,
    GateScope,
    isAllScope,
    PartitionScope,
    Scope,
    SiteScope,
    toggleAlarm,
    toggleAllAlarms,
    toggleAllCameras,
    toggleAllGates,
    toggleAllPartitions,
    toggleAllSites,
    toggleAllUnits,
    toggleCamera,
    toggleGate,
    togglePartition,
    toggleSite,
    toggleUnit,
    UnitScope,
} from "../../api/Authz"
import { Customer, Site, Unit } from "../../api/Customer"
import { Alarm, Gate } from "../../api/portal/hooks/ScopeThings"
import { SystemTag } from "../newalarm/SystemTag"
import { IconText } from "./IconText"

export interface EditScopeDialogProps {
    initialScope: Scope
    customer: Customer
    sites: Map<number, Site>
    units: Map<string, Unit>
    gates: Map<string, Gate[]>
    alarms: Map<string, Alarm[]>
    onSave: (s: Scope) => void
    onClose: () => void
}

export function EditScopeDialog(props: EditScopeDialogProps) {
    const { initialScope, customer, sites, units, gates, alarms, onSave, onClose } = props

    const [scope, setScope] = useState(initialScope)

    const { t } = useTranslation()

    const siteUnits = useMemo(
        () =>
            new Map(
                Array.from(sites.values()).map((s) => [
                    s.ID,
                    Array.from(units.values()).filter((u) => u.SiteID === s.ID),
                ])
            ),
        [units, sites]
    )

    const doSave = () => {
        onSave(scope)
        onClose()
    }

    const doToggleAllSites = () => setScope((s) => toggleAllSites(s, customer.ID))
    const doToggleSite = (siteID: number) => setScope((s) => toggleSite(s, customer.ID, siteID))
    const doToggleAllUnits = (siteID: number) => setScope((s) => toggleAllUnits(s, customer.ID, siteID))
    const doToggleUnit = (siteID: number, unit: string) => setScope((s) => toggleUnit(s, customer.ID, siteID, unit))
    const doToggleAllCameras = (siteID: number, unit: string) =>
        setScope((s) => toggleAllCameras(s, customer.ID, siteID, unit))
    const doToggleCamera = (siteID: number, unit: string, cameraID: number) =>
        setScope((s) => toggleCamera(s, customer.ID, siteID, unit, cameraID))
    const doToggleAllGates = (siteID: number, unit: string) =>
        setScope((s) => toggleAllGates(s, customer.ID, siteID, unit))
    const doToggleGate = (siteID: number, unit: string, gateID: number) =>
        setScope((s) => toggleGate(s, customer.ID, siteID, unit, gateID))
    const doToggleAllAlarms = (siteID: number, unit: string) =>
        setScope((s) => toggleAllAlarms(s, customer.ID, siteID, unit))
    const doToggleAlarm = (siteID: number, unit: string, alarmID: number) =>
        setScope((s) => toggleAlarm(s, customer.ID, siteID, unit, alarmID))
    const doToggleAllPartitions = (siteID: number, unit: string, alarmID: number) =>
        setScope((s) => toggleAllPartitions(s, customer.ID, siteID, unit, alarmID))
    const doTogglePartition = (siteID: number, unit: string, alarmID: number, partitionID: number) =>
        setScope((s) => togglePartition(s, customer.ID, siteID, unit, alarmID, partitionID))

    const renderChoice = (
        checked: boolean,
        text: string,
        icon: ReactElement,
        onClick: () => void,
        style?: "bold" | "italic",
        chip?: ReactElement
    ) => (
        <Stack key={text} direction="row" spacing={1} alignItems="center">
            <Checkbox checked={checked} size="small" onClick={onClick} sx={{ p: "4px" }} />
            <IconText icon={icon} text={text} bold={style === "bold"} italic={style === "italic"} />
            {chip}
        </Stack>
    )

    const renderEmpty = (text: string) => (
        <Stack key={"empty"} direction="row" spacing={1} alignItems="center">
            <Checkbox size="small" sx={{ visibility: "hidden", p: "4px" }} />
            <IconText icon={<Close />} text={text} italic />
        </Stack>
    )

    const renderScope = () => {
        if (scope.customers?.length !== 1) {
            return <Typography>invalid customer scope, expects singular customer scope</Typography>
        }
        const c = scope.customers[0]
        if (c.all) {
            return <Typography>invalid customer scope, matches all customers</Typography>
        }
        return renderSites(c.sites || [])
    }

    const renderSites = (scope: SiteScope[]) => {
        const allSites = isAllScope(scope)

        return (
            <Stack>
                {renderChoice(allSites, t("field.all_sites"), <DoneAll />, doToggleAllSites, "italic")}
                {!allSites &&
                    Array.from(sites.values()).map((site) =>
                        renderSiteScope(
                            scope.find((s) => s.id === "" + site.ID),
                            site
                        )
                    )}
                {!allSites && sites.size === 0 && renderEmpty(t("field.no_sites"))}
            </Stack>
        )
    }

    const renderSiteScope = (scope: SiteScope | undefined, site: Site) => (
        <Fragment key={site.ID}>
            {renderChoice(!!scope, site.DisplayName, <LocationOn />, () => doToggleSite(site.ID), "bold")}
            {scope && <Stack pl={3}>{renderUnits(scope.units || [], site)}</Stack>}
        </Fragment>
    )

    const renderUnits = (scope: UnitScope[], site: Site) => {
        const allUnits = isAllScope(scope)

        return (
            <>
                {renderChoice(allUnits, t("field.all_units"), <DoneAll />, () => doToggleAllUnits(site.ID), "italic")}
                {!allUnits &&
                    siteUnits.get(site.ID)?.map((unit) =>
                        renderUnitScope(
                            scope.find((s) => s.shortName === unit.ShortName),
                            site,
                            unit
                        )
                    )}
                {!allUnits && siteUnits.get(site.ID)?.length === 0 && renderEmpty(t("field.no_units"))}
            </>
        )
    }

    const renderUnitScope = (scope: UnitScope | undefined, site: Site, unit: Unit) => (
        <Fragment key={unit.ShortName}>
            {renderChoice(!!scope, unit.DisplayName, <CellTower />, () => doToggleUnit(site.ID, unit.ShortName))}
            {scope && (
                <Stack pl={3}>
                    {renderCameras(scope.cameras || [], site, unit)}
                    <Divider sx={{ pl: 0, ml: "32px", my: "2px" }} />
                    {renderGates(scope.gates || [], site, unit)}
                    <Divider sx={{ pl: 0, ml: "32px", my: "2px" }} />
                    {renderAlarms(scope.alarms || [], site, unit)}
                </Stack>
            )}
        </Fragment>
    )

    const renderCameras = (scope: CameraScope[], site: Site, unit: Unit) => {
        const allCameras = isAllScope(scope)
        const unitCameras = unit.UnitConfig.Cameras || []

        return (
            <>
                {renderChoice(
                    allCameras,
                    t("field.all_cameras"),
                    <DoneAll />,
                    () => doToggleAllCameras(site.ID, unit.ShortName),
                    "italic"
                )}
                {!allCameras &&
                    unitCameras.map((camera) =>
                        renderChoice(
                            scope.some((s) => s.id === camera.ID + ""),
                            t("camera.nth_camera", { id: camera.ID }),
                            <CameraAlt />,
                            () => doToggleCamera(site.ID, unit.ShortName, camera.ID)
                        )
                    )}
                {!allCameras && unitCameras.length === 0 && renderEmpty(t("field.no_cameras"))}
            </>
        )
    }

    const renderGates = (scope: GateScope[], site: Site, unit: Unit) => {
        const allGates = isAllScope(scope)
        const unitGates = gates.get(unit.ShortName) || []

        return (
            <>
                {renderChoice(
                    allGates,
                    t("field.all_gates"),
                    <DoneAll />,
                    () => doToggleAllGates(site.ID, unit.ShortName),
                    "italic"
                )}
                {!allGates &&
                    unitGates.map((gate) =>
                        renderChoice(
                            scope.some((s) => s.id === gate.id + ""),
                            gate.displayName || t("unit.gate_display_name", { id: gate.id }),
                            <MeetingRoom />,
                            () => gate.id && doToggleGate(site.ID, unit.ShortName, gate.id)
                        )
                    )}
                {!allGates && unitGates.length === 0 && renderEmpty(t("field.no_gates"))}
            </>
        )
    }

    const renderAlarms = (scope: AlarmScope[], site: Site, unit: Unit) => {
        const allAlarms = isAllScope(scope)
        const unitAlarms = alarms.get(unit.ShortName) || []

        return (
            <>
                {renderChoice(
                    allAlarms,
                    t("field.all_alarms"),
                    <DoneAll />,
                    () => doToggleAllAlarms(site.ID, unit.ShortName),
                    "italic"
                )}
                {!allAlarms &&
                    unitAlarms.map((alarm) =>
                        renderAlarmScope(
                            scope.find((a) => a.id === alarm.id + ""),
                            site,
                            unit,
                            alarm
                        )
                    )}
                {!allAlarms && unitAlarms.length === 0 && renderEmpty(t("field.no_alarms"))}
            </>
        )
    }

    const renderAlarmScope = (scope: AlarmScope | undefined, site: Site, unit: Unit, alarm: Alarm) => (
        <Fragment key={alarm.id}>
            {renderChoice(
                !!scope,
                alarm.displayName || t("unit.alarm_display_name", { id: alarm.id }),
                <NotificationsActive />,
                () => alarm.id && doToggleAlarm(site.ID, unit.ShortName, alarm.id)
            )}
            {scope && <Stack pl={3}>{renderPartitions(scope?.partitions || [], site, unit, alarm)}</Stack>}
        </Fragment>
    )

    const renderPartitions = (scope: PartitionScope[], site: Site, unit: Unit, alarm: Alarm) => {
        const allPartitions = isAllScope(scope)
        const partitions = alarm.partitions || []

        return (
            <>
                {renderChoice(
                    allPartitions,
                    t("field.all_partitions"),
                    <DoneAll />,
                    () => alarm.id && doToggleAllPartitions(site.ID, unit.ShortName, alarm.id),
                    "italic"
                )}
                {!allPartitions &&
                    partitions.map((partition) =>
                        renderChoice(
                            scope.some((p) => p.id === partition.id + ""),
                            partition.displayName || t("alarm.partition_display_name", { id: partition.id }),
                            <PentagonOutlined />,
                            () =>
                                alarm.id &&
                                partition.id &&
                                doTogglePartition(site.ID, unit.ShortName, alarm.id, partition.id),
                            undefined,
                            partition.system ? <SystemTag small /> : undefined
                        )
                    )}
                {!allPartitions && partitions.length === 0 && renderEmpty(t("field.no_partitions"))}
            </>
        )
    }

    return (
        <Dialog onClose={onClose} aria-labelledby="edit-scope-dialog-title" open={true}>
            <DialogTitle id="edit-scope-dialog-title">{t("dialog.permission.edit_scope_title")}</DialogTitle>
            <Divider />
            <DialogContent>{renderScope()}</DialogContent>
            <Divider />
            <DialogActions sx={{ px: 2 }}>
                <Button onClick={doSave} variant="contained" color="primary">
                    {t("action.save")}
                </Button>
                <Button onClick={onClose} variant="contained" color="primary">
                    {t("action.cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
