import { useMemo } from "react"
import { thingIndex } from "../../../services/IoT"
import { Customer } from "../../Customer"
import { Thing, useGetCustomerThingsQuery } from "../Things"
import { RTKQuery } from "./RTK"

export interface Gate {
    id?: number
    displayName?: string
}

export interface Partition {
    id?: number
    displayName?: string
    system?: boolean
}

export interface Alarm {
    id?: number
    displayName?: string
    partitions?: Partition[]
}

export interface ScopeThings {
    gates: Map<string, Gate[]>
    alarms: Map<string, Alarm[]>
    query: RTKQuery
    refetch: () => void
}

const classes = ["gate", "alarm", "alarm_partition"]

const extractGates = (things: Thing[]) => {
    const result = new Map<string, Gate[]>()

    things
        .filter(t => t.manifest.labels?.class === "gate")
        .forEach(t => {
            const unit = t.manifest.labels?.unit || ""
            const gates = result.get(unit) || []
            if (gates.length === 0) {
                result.set(unit, gates)
            }
            gates.push({
                id: thingIndex(t.name),
                displayName: t.manifest.annotations?.display_name,
            })
        })

    return result
}

const extractAlarms = (things: Thing[]) => {
    const allAlarms = new Map<string, Alarm>()
    const result = new Map<string, Alarm[]>()

    things
        .filter(t => t.manifest.labels?.class === "alarm")
        .forEach(t => {
            const unit = t.manifest.labels?.unit || ""
            const alarm = {
                id: thingIndex(t.name),
                displayName: t.manifest.annotations?.display_name,
            }
            const alarms = result.get(unit) || []
            if (alarms.length === 0) {
                result.set(unit, alarms)
            }
            alarms.push(alarm)
            allAlarms.set(t.name, alarm)
        })

    things
        .filter(t => t.manifest.labels?.class === "alarm_partition")
        .forEach(t => {
            const unit = t.manifest.labels?.unit || ""
            const alarmName = `${unit}.alarm_${t.manifest.labels?.alarm}`
            const alarm = allAlarms.get(alarmName)
            if (!alarm) {
                console.warn("Unknown alarm", alarmName)
                return
            }
            const partitions = alarm.partitions || []
            if (partitions.length === 0) {
                alarm.partitions = partitions
            }
            partitions.push({
                id: thingIndex(t.name),
                displayName: t.manifest.annotations?.display_name,
                system: t.manifest.labels?.member_system === "true",
            })
        })

    return result
}

export const useScopeThings = (customer: Customer) => {
    const siteThingsQuery = useGetCustomerThingsQuery({ customer, classes })
    const refetch = siteThingsQuery.refetch

    const result = useMemo(
        (): ScopeThings => ({
            gates: extractGates(siteThingsQuery.data || []),
            alarms: extractAlarms(siteThingsQuery.data || []),
            query: siteThingsQuery,
            refetch,
        }),
        [siteThingsQuery, refetch],
    )

    return result
}
