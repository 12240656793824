import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Event, ToEventDetails, asLabels } from "../api/Event"
import { Second } from "../config/time"
import { monoEndpointURL } from "../config/urls"
import { useBackoff } from "../hooks/backoff"

const reconnectDelay = 5 * Second

export const allEvents = () => true

interface StreamEvent {
    value?: ValueEvent
}

interface ValueEvent {
    item: string
    thing: string
    value: Value
}

interface Value {
    clientId: string
    labels: Object
    timestamp: string
    operation: "CHANGED" | "INITIALIZED"
    number?: number
}

export function useEventStream(
    siteID: number,
    unit: string | undefined,
    active: boolean,
    stale: boolean,
    onlyChanges: boolean,
    filter: (event: Event) => boolean,
    onMessage: (event: Event) => void,
    onConnected?: () => void,
    onDisconnected?: () => void
) {
    const { t } = useTranslation()
    const [online, setOnline] = useState(false)
    const { attempt, retry, cancel } = useBackoff(reconnectDelay)

    const handleConnect = useCallback(() => {
        console.log(`Event stream for site ${siteID} connected.`)
        setOnline(true)
        onConnected?.()
    }, [onConnected, siteID])

    const handleDisconnect = useCallback(() => {
        console.log(`Event stream for site ${siteID} disconnected.`)
        setOnline(false)
        onDisconnected?.()
    }, [onDisconnected, siteID])

    useEffect(() => {
        if (!active) {
            return
        }

        const now = new Date()
        const url = unit
            ? monoEndpointURL(`watch/sites/${siteID}/items?thing=${unit}.*&include=value`)
            : monoEndpointURL(`watch/sites/${siteID}/items?include=value`)
        const source = new EventSource(url)

        source.onmessage = (event) => {
            if (event.type !== "message") {
                console.log("SSE Event: not a message.", event.type, event.data)
                return
            }

            const payload = JSON.parse(event.data) as StreamEvent

            if (payload.value === undefined) {
                console.log("SSE Event: no payload value.", event.data)
                return
            }
            if (onlyChanges && payload.value.value.operation !== "CHANGED") {
                console.log("SSE Event: ignoring non-CHANGED value.", event.data)
                return
            }

            const thing = payload.value.thing
            const item = payload.value.item
            const timestamp = new Date(payload.value.value.timestamp)
            const value = payload.value.value.number
            const labels = asLabels(payload.value.value.labels)
            const clientId = payload.value.value.clientId
            const details = ToEventDetails(thing, item, value, labels, t)

            const parsedEvent: Event = {
                ...details,
                thing,
                item,
                timestamp,
                value,
                labels,
                clientId,
            }

            if (!stale && parsedEvent.timestamp < now) {
                console.log("SSE Event: stale event.", parsedEvent)
                return
            }

            if (!filter(parsedEvent)) {
                console.log("SSE Event: filtered out.", parsedEvent)
                return
            }
            console.log("SSE Event: success.", parsedEvent)
            onMessage(parsedEvent)
        }

        source.onerror = () => {
            handleDisconnect()
            source.close()
            retry()
        }
        source.onopen = () => {
            handleConnect()
        }

        return () => {
            if (source.readyState !== source.CLOSED) {
                source.close()
            }
            setOnline(false)
            cancel()
        }
    }, [active, unit, siteID, filter, onMessage, handleConnect, handleDisconnect, attempt, retry, cancel, stale, onlyChanges, t])

    return {
        online,
    }
}
