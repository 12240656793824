import {
    DirectionsRun,
    EmojiPeople,
    Face,
    FileDownload,
    FileUpload,
    Grid4x4,
    Hardware,
    Pentagon,
    PentagonOutlined,
    SensorOccupied,
    VerticalAlignCenter,
} from "@mui/icons-material"
import { Alert, Stack, Typography, useTheme } from "@mui/material"
import { Box } from "@mui/system"
import { useConfirm } from "material-ui-confirm"
import { useSnackbar } from "notistack"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useFullscreen } from "rooks"
import { Operation } from "../../api/Authz"
import { CameraConfig, Site, Unit } from "../../api/Customer"
import {
    DeviceInformation,
    GetImagingOptionsResponse,
    GetImagingSettingsResponse,
    GetNodesResponse,
    GetPresetsResponse,
    ImagingOptions,
    ImagingSettings,
    InterfacesResponse,
    Preset,
    PTZNode,
    RebootResponse,
    Target,
} from "../../api/PTZ"
import { IsLive, Playback } from "../../api/Video"
import { useCameraPermission, useUnitPermission } from "../../auth/AuthorizerProvider"
import { http, noSnackBar, rawHttp, snackbarSuccess } from "../../backend/request"
import { PickCommandDialog } from "../../components/PickCommandDialog"
import { PickPresetDialog } from "../../components/PickPresetDialog"
import { PickTargetDialog } from "../../components/PickTargetDialog"
import { PresetDialog } from "../../components/PresetDialog"
import { Milli } from "../../config/time"
import { eyeTrackEndpointURL } from "../../config/urls"
import { Flags } from "../../hooks/flags"
import { useQueue } from "../../hooks/queue"
import { Timeline } from "../common/Timeline"
import { NumberChoice } from "../common/TimelineChoice"
import { Camera, CameraState } from "./Camera"
import { CameraDialog } from "./CameraDialog"
import { CameraTag } from "./CameraTag"
import { CameraTitle } from "./CameraTitle"
import { PTZControls } from "./PTZControls"
import { SettingsControls } from "./SettingsControls"
import { flipUnitSwitch, StateOFF, StateON, unitLight } from "../../api/OpenHAB"
import { SendCommand } from "../../api/Command"

export interface LabelledCameraProps {
    site: Site
    unit: Unit
    units: Unit[]
    camera: CameraConfig
    fitParent?: boolean
    preferFullscreen?: boolean
    playback: Playback
    toggledHistory: boolean
    disableHistory?: boolean
    embedHistory?: boolean
    small: boolean
    initialShowSettings?: boolean
    initialShowPTZ?: boolean
    pinned: boolean
    motionAlarms: Flags
    displayUnit?: boolean
    hasEvents: boolean
    eventsPlayback: boolean
    window: NumberChoice
    delay?: number
    lightSwitchState?: boolean
    onWindowChange?: (window: NumberChoice) => void
    onSetEventsPlayback: (value: boolean) => void
    onClick?: () => void
    onPlaybackChange: (playback: Playback) => void
    onHistory: () => void
    onPin?: () => void
    onResolution?: (width: number, height: number) => void
}

export function LabelledCamera(props: LabelledCameraProps) {
    const {
        site,
        unit,
        units,
        camera,
        fitParent,
        preferFullscreen,
        playback,
        disableHistory,
        toggledHistory,
        lightSwitchState,
        embedHistory,
        small,
        pinned,
        initialShowSettings,
        initialShowPTZ,
        displayUnit,
        hasEvents,
        eventsPlayback,
        window,
        delay,
        onWindowChange,
        onSetEventsPlayback,
        onClick,
        onPlaybackChange,
        onHistory,
        onPin,
        onResolution,
        motionAlarms,
    } = props

    const { t } = useTranslation()
    const theme = useTheme()

    const [showSettings, setShowSettings] = useState(initialShowSettings || false)
    const [showPTZ, setShowPTZ] = useState(initialShowPTZ || false)
    const [popupCamera, setPopupCamera] = useState(false)
    const [cameraState, setCameraState] = useState(CameraState.Offline)
    const [bitrate, setBitrate] = useState<number | undefined>(undefined)
    const [fps, setFPS] = useState<number | undefined>(undefined)
    const [width, setWidth] = useState<number | undefined>(undefined)
    const [height, setHeight] = useState<number | undefined>(undefined)

    const [aimPresetDialogOpen, setAimPresetDialogOpen] = useState(false)
    const [savePresetDialogOpen, setSavePresetDialogOpen] = useState(false)
    const [deletePresetDialogOpen, setDeletePresetDialogOpen] = useState(false)
    const [edittingPreset, setEdittingPreset] = useState<Preset | null>(null)
    const [addingPreset, setAddingPreset] = useState(false)
    const [aimTargetDialogOpen, setAimTargetDialogOpen] = useState(false)
    const [executingCommand, setExecutingCommand] = useState(false)
    const [presets, setPresets] = useState<Preset[]>([])
    const [targets, setTargets] = useState<Target[]>([])
    const [imagingOptions, setImagingOptions] = useState<ImagingOptions | null>(null)
    const [imagingSettings, setImagingSettings] = useState<ImagingSettings | null>(null)
    const [deviceInfo, setDeviceInfo] = useState<DeviceInformation | null>(null)
    const [node, setNode] = useState<PTZNode | null>(null)

    const fullScreenRef = useRef<HTMLDivElement>(null)
    const { isFullscreenAvailable, isFullscreenEnabled, toggleFullscreen } = useFullscreen({ target: fullScreenRef })

    const snackbar = useSnackbar()
    const confirm = useConfirm()
    const { push: moveQueue } = useQueue(1, 300 * Milli)
    const { push: focusQueue } = useQueue(1, 500 * Milli)

    const allowViewTracking = useUnitPermission(Operation.VIEW_TRACKING, unit)
    const allowMove = useCameraPermission(Operation.MOVE_CAMERA, unit, camera.ID)
    const allowGetPresets = useCameraPermission(Operation.LIST_CAMERA_PRESETS, unit, camera.ID)
    const allowGetCamera = useCameraPermission(Operation.GET_CAMERA, unit, camera.ID)
    const allowHistory = useCameraPermission(Operation.STREAM_CAMERA_ARCHIVE, unit, camera.ID)
    const allowDownload = useCameraPermission(Operation.EXPORT_CAMERA_ARCHIVE, unit, camera.ID)
    const allowLightSwitch = useUnitPermission(Operation.SWITCH_LIGHT, unit)
    const allowExecuteCommands = useCameraPermission(Operation.EXECUTE_CAMERA_COMMAND, unit, camera.ID)

    const supportsRelativePanTilt = useMemo(
        () =>
            !!node?.SupportedPTZSpaces?.ContinuousPanTiltVelocitySpace?.some(
                (s) => s.URI === "http://www.onvif.org/ver10/tptz/PanTiltSpaces/VelocityGenericSpace"
            ),
        [node]
    )
    const supportsRelativeZoom = useMemo(
        () =>
            !!node?.SupportedPTZSpaces?.ContinuousZoomVelocitySpace?.some(
                (s) => s.URI === "http://www.onvif.org/ver10/tptz/ZoomSpaces/VelocityGenericSpace"
            ),
        [node]
    )
    const supportsPresets = !!node?.MaximumNumberOfPresets && node.MaximumNumberOfPresets > 0
    const supportsFocusToggle =
        !!imagingOptions?.Focus?.AutoFocusModes && imagingOptions.Focus.AutoFocusModes.length > 1
    const isManualFocus = imagingSettings?.Focus?.AutoFocusMode === "MANUAL"
    const homePreset = useMemo(() => presets?.find((p) => p.Name.toLowerCase() === "home"), [presets])
    const hasHome = !!node?.HomeSupported
    const supportsHome = hasHome || homePreset !== undefined
    const supportsAuxiliaryCommands = !!node?.AuxiliaryCommands

    const allowPanTilt = supportsRelativePanTilt && allowMove
    const allowZoom = supportsRelativeZoom && allowMove
    const allowFocusToggle = supportsFocusToggle && allowMove
    const allowManualFocus = isManualFocus && allowMove
    const allowHome = supportsHome && allowMove
    const allowAuxiliaryCommands = supportsAuxiliaryCommands && allowExecuteCommands
    const allowPTZ =
        (allowPanTilt || allowZoom || allowFocusToggle || allowManualFocus || allowHome) && IsLive(playback)
    const allowSettings = allowGetCamera && allowMove && (allowAuxiliaryCommands || supportsPresets) && IsLive(playback)

    useEffect(() => {
        if (!allowGetCamera) {
            return
        }
        http<GetNodesResponse>(
            "Getting camera nodes",
            eyeTrackEndpointURL(unit, `cameras/${camera.ID}/nodes`),
            noSnackBar
        )
            .then((result) => {
                console.log("Got camera nodes", result)
                setNode(result.PTZNode[0])
            })
            .catch((e) => console.log(e))
    }, [camera, unit, allowGetCamera])

    const reloadPresets = useCallback(() => {
        if (!supportsPresets || !allowGetPresets) {
            return
        }
        http<GetPresetsResponse>(
            "Getting camera presets",
            eyeTrackEndpointURL(unit, `cameras/${camera.ID}/presets`),
            noSnackBar
        )
            .then((result) => {
                console.log("Got camera presets", result)
                setPresets(
                    (result.Preset || []).filter((p) => p.token).map((p) => ({ token: p.token, Name: p.Name || "" }))
                )
            })
            .catch((e) => console.log(e))
    }, [camera, unit, supportsPresets, allowGetPresets])

    useEffect(() => {
        reloadPresets()
    }, [reloadPresets])

    useEffect(() => {
        if (!allowPanTilt || !allowViewTracking) {
            return
        }
        http<Target[]>("Getting unit targets", eyeTrackEndpointURL(unit, `devices`), noSnackBar)
            .then((result) => {
                console.log("Got unit targets", result)
                setTargets(result || [])
            })
            .catch((e) => console.log(e))
    }, [unit, allowPanTilt, allowViewTracking])

    useEffect(() => {
        if (!allowGetCamera || !allowMove) {
            return
        }
        http<GetImagingOptionsResponse>(
            "Getting camera imaging options",
            eyeTrackEndpointURL(unit, `cameras/${camera.ID}/imaging-options`),
            noSnackBar
        )
            .then((result) => {
                console.log("Got camera imaging options", result)
                setImagingOptions(result.ImagingOptions)
            })
            .catch((e) => console.log(e))
    }, [camera, unit, allowGetCamera, allowMove])

    const loadInterfaces = useCallback(() => {
        if (!allowGetCamera) {
            return Promise.reject()
        }
        return http<InterfacesResponse>(
            "Getting camera network interfaces",
            eyeTrackEndpointURL(unit, `cameras/${camera.ID}/system/interfaces`),
            noSnackBar
        ).then((result) => {
            console.log("Got camera network interfaces", result)
            return result
        })
    }, [camera, unit, allowGetCamera])

    const reloadImagingSettings = useCallback(() => {
        if (!allowGetCamera || !allowMove) {
            return
        }
        http<GetImagingSettingsResponse>(
            "Getting camera imaging settings",
            eyeTrackEndpointURL(unit, `cameras/${camera.ID}/imaging-settings`),
            noSnackBar
        )
            .then((result) => {
                console.log("Got camera imaging settings", result)
                setImagingSettings(result.ImagingSettings)
            })
            .catch((e) => console.log(e))
    }, [camera, unit, allowMove, allowGetCamera])

    useEffect(() => {
        reloadImagingSettings()
    }, [reloadImagingSettings])

    useEffect(() => {
        if (!allowGetCamera) {
            return
        }
        http<DeviceInformation>(
            "Getting camera device information",
            eyeTrackEndpointURL(unit, `cameras/${camera.ID}/system/info`),
            noSnackBar
        )
            .then((result) => {
                console.log("Got camera device information", result)
                setDeviceInfo(result)
            })
            .catch((e) => console.log(e))
    }, [camera, unit, allowGetCamera])

    const showHistory = useMemo(() => {
        const result = unit.Revision > 2 && allowHistory && toggledHistory && (embedHistory || isFullscreenEnabled)
        if (result && showSettings) {
            setShowSettings(false)
        }
        return result
    }, [unit, allowHistory, toggledHistory, embedHistory, isFullscreenEnabled, showSettings])

    const onVideoClick = useCallback(() => {
        const useFullscreen = preferFullscreen && isFullscreenAvailable
        if (!isFullscreenEnabled && !useFullscreen) {
            setPopupCamera(true)
            return
        }
        toggleFullscreen()
    }, [isFullscreenAvailable, isFullscreenEnabled, preferFullscreen, toggleFullscreen])

    const gotoHomePosition = useCallback(() => {
        rawHttp("Aiming camera at home position", eyeTrackEndpointURL(unit, `cameras/${camera.ID}/home`), noSnackBar)
            .then((response) => console.log("Home position aimed", response))
            .catch((e) => console.log(e))
    }, [camera.ID, unit])

    const renderWithWarning = (text: string, warning: string) => (
        <Stack spacing={1}>
            <Typography>{text}</Typography>
            <Alert severity="warning">{warning}</Alert>
        </Stack>
    )

    const setHomePosition = useCallback(() => {
        confirm({
            title: t("confirm.generic"),
            confirmationText: t("action.save"),
            confirmationButtonProps: {
                color: "secondary",
            },
            content: renderWithWarning(t("confirm.save_home"), t("confirm.video_analysis_warning")),
            dialogProps: { container: fullScreenRef.current || undefined },
        })
            .then(() =>
                rawHttp(
                    "Saving camera home position",
                    eyeTrackEndpointURL(unit, `cameras/${camera.ID}/home`),
                    noSnackBar,
                    { method: "PUT" }
                )
                    .then((response) => console.log("Home position set", response))
                    .catch((e) => console.log(e))
            )
            .catch(() => {})
    }, [camera.ID, unit, confirm, t])

    const gotoPreset = useCallback(
        (preset: Preset) => {
            rawHttp(
                `Aiming camera at preset ${preset.Name} (${preset.token})`,
                eyeTrackEndpointURL(unit, `cameras/${camera.ID}/preset/${preset.token}`),
                noSnackBar
            )
                .then((response) => console.log(`Preset ${preset.Name} (${preset.token}) aimed`, response))
                .catch((e) => console.log(e))
        },
        [camera.ID, unit]
    )

    const savePreset = useCallback(
        (preset: Preset) => {
            confirm({
                title: t("confirm.generic"),
                content: renderWithWarning(
                    t("confirm.save_preset", { preset: preset.Name }),
                    t("confirm.video_analysis_warning")
                ),
                confirmationText: t("action.save"),
                confirmationButtonProps: {
                    color: "secondary",
                },
                dialogProps: { container: fullScreenRef.current || undefined },
            })
                .then(() =>
                    rawHttp(
                        `Saving camera at preset ${preset.Name} (${preset.token})`,
                        eyeTrackEndpointURL(unit, `cameras/${camera.ID}/preset/${preset.token}`),
                        noSnackBar,
                        {
                            method: "PUT",
                            body: JSON.stringify({
                                Name: preset.Name,
                            }),
                        }
                    )
                        .then((response) => console.log(`Preset ${preset.Name} (${preset.token}) saved`, response))
                        .catch((e) => console.log(e))
                        .finally(reloadPresets)
                )
                .catch(() => {})
        },
        [camera.ID, unit, reloadPresets, confirm, t]
    )

    const deletePreset = useCallback(
        (preset: Preset) => {
            confirm({
                title: t("confirm.generic"),
                content: renderWithWarning(
                    t("confirm.clear_preset", { preset: preset.Name }),
                    t("confirm.video_analysis_warning")
                ),
                confirmationText: t("action.clear"),
                confirmationButtonProps: {
                    color: "secondary",
                },
                dialogProps: { container: fullScreenRef.current || undefined },
            })
                .then(() =>
                    rawHttp(
                        `Clearing camera at preset ${preset.Name} (${preset.token})`,
                        eyeTrackEndpointURL(unit, `cameras/${camera.ID}/preset/${preset.token}`),
                        noSnackBar,
                        { method: "DELETE" }
                    )
                        .then((response) => console.log(`Preset ${preset.Name} (${preset.token}) deleted`, response))
                        .catch((e) => console.log(e))
                        .finally(reloadPresets)
                )
                .catch(() => {})
        },
        [camera.ID, unit, reloadPresets, confirm, t]
    )

    const addPreset = useCallback(
        (preset: Preset) => {
            confirm({
                title: t("confirm.generic"),
                description: t("confirm.add_preset", { preset: preset.Name }),
                confirmationText: t("action.add"),
                confirmationButtonProps: {
                    color: "secondary",
                },
                dialogProps: { container: fullScreenRef.current || undefined },
            })
                .then(() =>
                    rawHttp(
                        `Adding camera at preset ${preset.Name}`,
                        eyeTrackEndpointURL(unit, `cameras/${camera.ID}/presets`),
                        noSnackBar,
                        {
                            method: "POST",
                            body: JSON.stringify({
                                Name: preset.Name,
                            }),
                        }
                    )
                        .then((response) => console.log(`Preset ${preset.Name} added`, response))
                        .catch((e) => console.log(e))
                        .finally(reloadPresets)
                )
                .catch(() => {})
        },
        [camera.ID, unit, reloadPresets, confirm, t]
    )

    const aimTarget = useCallback(
        (target: Target) => {
            rawHttp(
                `Aiming camera at target ${target.Name} (${target.ID})`,
                eyeTrackEndpointURL(unit, `cameras/${camera.ID}/target`),
                noSnackBar,
                {
                    method: "PUT",
                    body: target.ID,
                }
            )
                .then((response) => console.log(`target ${target.Name} (${target.ID}) aimed`, response))
                .catch((e) => console.log(e))
        },
        [camera.ID, unit]
    )

    const executeCommand = useCallback(
        (command: string) => {
            rawHttp(
                `Executing camera at command ${command}`,
                eyeTrackEndpointURL(unit, `cameras/${camera.ID}/command`),
                noSnackBar,
                {
                    method: "POST",
                    body: JSON.stringify({
                        Command: command,
                    }),
                }
            )
                .then((response) => console.log(`Command ${command} executed`, response))
                .catch((e) => console.log(e))
        },
        [camera.ID, unit]
    )

    const aimHome = useCallback(() => {
        if (hasHome) {
            gotoHomePosition()
        } else if (homePreset !== undefined) {
            gotoPreset(homePreset)
        } else {
            console.warn("Cannot aim home, home position is not supported and home preset is not set.")
        }
    }, [homePreset, hasHome, gotoHomePosition, gotoPreset])

    const startMoving = useCallback(
        (x: number, y: number, zoom: number) => {
            moveQueue(() =>
                rawHttp(
                    "Setting camera move speed",
                    eyeTrackEndpointURL(unit, `cameras/${camera.ID}/speed`),
                    noSnackBar,
                    {
                        method: "PUT",
                        body: JSON.stringify({
                            Pan: x,
                            Tilt: y,
                            Zoom: zoom,
                        }),
                    }
                )
                    .then((response) => console.log("Camera move speed set", x, y, zoom, response))
                    .catch((e) => console.log(e))
            )
        },
        [unit, camera, moveQueue]
    )

    const stopMoving = useCallback(() => {
        startMoving(0, 0, 0)
    }, [startMoving])

    const startFocusing = useCallback(
        (speed: number) => {
            focusQueue(() =>
                rawHttp("Focusing camera", eyeTrackEndpointURL(unit, `cameras/${camera.ID}/focus-speed`), noSnackBar, {
                    method: "PUT",
                    body: JSON.stringify({
                        Speed: speed,
                    }),
                })
                    .then((response) => console.log("Camera focus speed set", speed, response))
                    .catch((e) => console.log(e))
            )
        },
        [camera.ID, unit, focusQueue]
    )

    const stopFocusing = useCallback(() => {
        startFocusing(0)
    }, [startFocusing])

    const reboot = useCallback(() => {
        confirm({
            title: t("confirm.generic"),
            description: t("confirm.reboot_camera", { camera: `Cam${camera.ID}` }),
            confirmationText: t("action.reboot"),
            confirmationButtonProps: {
                color: "secondary",
            },
            dialogProps: { container: fullScreenRef.current || undefined },
        })
            .then(() =>
                http<RebootResponse>(
                    "Rebooting camera",
                    eyeTrackEndpointURL(unit, `cameras/${camera.ID}/system/reboot`),
                    noSnackBar,
                    { method: "PUT" }
                )
                    .then((response) => {
                        snackbarSuccess(response.Message || "Reboot requested", snackbar)
                        console.log(`Reboot initiated`, response)
                    })
                    .catch((e) => console.log(e))
            )
            .catch(() => {})
    }, [camera.ID, unit, confirm, snackbar, t])

    const toggleFocus = useCallback(() => {
        const value = imagingSettings?.Focus?.AutoFocusMode === "MANUAL" ? "AUTO" : "MANUAL"
        const req: ImagingSettings = {
            Focus: {
                AutoFocusMode: value,
            },
        }
        rawHttp(
            `Setting autofocus to ${value}`,
            eyeTrackEndpointURL(unit, `cameras/${camera.ID}/imaging-settings`),
            noSnackBar,
            {
                method: "PUT",
                body: JSON.stringify({ Settings: req }),
            }
        )
            .then((response) => console.log(`Autofocus set to ${value}`, response))
            .catch((e) => console.log(e))
            .finally(reloadImagingSettings)
    }, [camera.ID, unit, reloadImagingSettings, imagingSettings])

    const onBytesReceived = useMemo(() => {
        let lastTime = 0
        let lastBytes = 0
        let br = 0
        return (bytes: number) => {
            const time = new Date().getTime()
            if (bytes < lastBytes) {
                lastBytes = 0
                br = 0
            }
            if (lastBytes !== 0) {
                const current = ((bytes - lastBytes) / (time - lastTime)) * 1000 * 8
                // Smooth the change over time.
                br = 0.8 * br + 0.2 * current
                setBitrate(br)
            }
            lastBytes = bytes
            lastTime = time
        }
    }, [])

    const onFrames = useMemo(() => {
        let lastTime = new Date().getTime()
        let lastFrames = 0
        let fr = 0
        return (frames: number) => {
            const time = new Date().getTime()
            if (frames < lastFrames) {
                lastFrames = 0
                fr = 0
            }
            if (lastFrames !== 0) {
                const current = ((frames - lastFrames) / (time - lastTime)) * 1000
                // Smooth the change over time.
                fr = 0.5 * fr + 0.5 * current
                setFPS(fr)
            }
            lastFrames = frames
            lastTime = time
        }
    }, [])

    const changeResolution = useCallback(
        (width: number, height: number) => {
            setWidth(width)
            setHeight(height)
            if (onResolution) {
                onResolution(width, height)
            }
        },
        [onResolution]
    )

    const motionAlarm = useMemo(() => motionAlarms.size > 0, [motionAlarms])

    const flipLightSwitch = useCallback(
        (unit: Unit, lightOn?: boolean) => {
            if (lightOn === undefined) return

            const newLight = unit.Revision && unit.Revision > 6
            const lightThingName = `${unit.ShortName}.light_1`
            if (newLight) {
                return SendCommand(site, "switch", lightOn ? 0 : 1, lightThingName)
            }

            const state = lightOn ? StateON : StateOFF
            return flipUnitSwitch({ name: lightThingName, state: state }, unitLight(unit), snackbar)
        },
        [site, snackbar]
    )

    return (
        <Box
            height="100%"
            width="100%"
            sx={{
                borderColor: motionAlarm ? "red" : pinned ? theme.palette.warning.main : theme.palette.panel.border,
                borderStyle: "solid",
                borderWidth: 1,
            }}
            overflow="hidden"
        >
            <Stack ref={fullScreenRef} height="100%" width="100%">
                <Box p={0} m={0} flexGrow={1} sx={{ background: "black", position: "relative" }}>
                    <CameraTitle
                        cameraID={camera.ID}
                        unitName={displayUnit ? unit.ShortName : undefined}
                        deviceInfo={deviceInfo}
                        allowSettings={allowSettings}
                        allowPTZ={allowPTZ}
                        allowFullscreen={isFullscreenAvailable}
                        allowHistory={allowHistory && !disableHistory}
                        allowPin={onPin !== undefined}
                        allowLightSwitch={allowLightSwitch}
                        cameraState={cameraState}
                        bitrate={bitrate}
                        fps={fps}
                        width={width}
                        height={height}
                        toggledSettings={showSettings}
                        toggledPTZ={showPTZ}
                        toggledFullscreen={isFullscreenEnabled}
                        toggledHistory={toggledHistory}
                        toggledPin={pinned}
                        toggleLightSwitch={lightSwitchState}
                        onSettings={() => setShowSettings((s) => !s)}
                        onPTZ={() => setShowPTZ((s) => !s)}
                        onFullscreen={toggleFullscreen}
                        onHistory={onHistory}
                        onPin={() => (onPin ? onPin() : undefined)}
                        onLoadInterfaces={loadInterfaces}
                        onLightSwitch={() => flipLightSwitch(unit, lightSwitchState)}
                    />
                    <Camera
                        unit={unit}
                        camera={camera}
                        region={site.SiteConfig.region || ""}
                        fitParent={fitParent || isFullscreenEnabled}
                        delay={delay}
                        onVideoClick={onClick || onVideoClick}
                        onStateChange={setCameraState}
                        onResolution={changeResolution}
                        onBytesReceived={onBytesReceived}
                        onFrames={onFrames}
                        disabled={popupCamera}
                        playback={playback}
                    />
                    {showPTZ && IsLive(playback) && (
                        <PTZControls
                            allowPanTilt={allowPanTilt}
                            allowZoom={allowZoom}
                            allowHome={allowHome}
                            allowFocusToggle={allowFocusToggle}
                            allowManualFocus={allowMove}
                            isManualFocus={isManualFocus}
                            onAimHome={aimHome}
                            onStartMoving={startMoving}
                            onStopMoving={stopMoving}
                            onStartFocusing={startFocusing}
                            onStopFocusing={stopFocusing}
                            onToggleFocus={toggleFocus}
                        />
                    )}
                    {motionAlarm && (
                        <Stack
                            spacing="2px"
                            direction="row"
                            style={{
                                zIndex: 20,
                                position: "absolute",
                                bottom: 2,
                                right: 2,
                                borderWidth: "0px",
                                padding: "0px",
                                margin: "0px",
                                boxShadow: "3px",
                            }}
                        >
                            {(motionAlarms.has("motion_alarm") || motionAlarms.has("motion_detector")) && (
                                <CameraTag icon={<DirectionsRun />} tooltip={t("event.motion_detector_tooltip")} />
                            )}
                            {motionAlarms.has("cell_motion_detector") && (
                                <CameraTag icon={<Grid4x4 />} tooltip={t("event.cell_motion_detector_tooltip")} />
                            )}
                            {motionAlarms.has("field_detector_inside") && (
                                <CameraTag icon={<Pentagon />} tooltip={t("event.field_detector_inside_tooltip")} />
                            )}
                            {motionAlarms.has("field_detector_outside") && (
                                <CameraTag
                                    icon={<PentagonOutlined />}
                                    tooltip={t("event.field_detector_outside_tooltip")}
                                />
                            )}
                            {motionAlarms.has("object_detector_left") && (
                                <CameraTag icon={<FileDownload />} tooltip={t("event.object_detector_left_tooltip")} />
                            )}
                            {motionAlarms.has("object_detector_removed") && (
                                <CameraTag icon={<FileUpload />} tooltip={t("event.object_detector_removed_tooltip")} />
                            )}
                            {motionAlarms.has("face_detector") && (
                                <CameraTag icon={<Face />} tooltip={t("event.face_detector_tooltip")} />
                            )}
                            {motionAlarms.has("human_detector") && (
                                <CameraTag icon={<EmojiPeople />} tooltip={t("event.human_detector_tooltip")} />
                            )}
                            {motionAlarms.has("tamper_detector") && (
                                <CameraTag icon={<Hardware />} tooltip={t("event.tamper_detector_tooltip")} />
                            )}
                            {motionAlarms.has("loitering_detector") && (
                                <CameraTag icon={<SensorOccupied />} tooltip={t("event.loitering_detector_tooltip")} />
                            )}
                            {motionAlarms.has("line_detector") && (
                                <CameraTag icon={<VerticalAlignCenter />} tooltip={t("event.line_detector_tooltip")} />
                            )}
                        </Stack>
                    )}
                    {showSettings && IsLive(playback) && (
                        <SettingsControls
                            unit={unit}
                            cameraID={camera.ID}
                            allowMove={allowMove}
                            hasPresets={presets.length > 0}
                            hasHome={hasHome}
                            hasCommands={allowAuxiliaryCommands}
                            hasTargets={targets.length > 0}
                            onAimHome={gotoHomePosition}
                            onSaveHome={setHomePosition}
                            onAimPreset={() => setAimPresetDialogOpen(true)}
                            onSavePreset={() => setSavePresetDialogOpen(true)}
                            onClearPreset={() => setDeletePresetDialogOpen(true)}
                            onAddPreset={() => setAddingPreset(true)}
                            onAimTarget={() => setAimTargetDialogOpen(true)}
                            onExecuteCommand={() => setExecutingCommand(true)}
                            onReboot={reboot}
                        />
                    )}
                    {showHistory && (
                        <Stack direction="row" zIndex={30} position="absolute" bottom={0} width="100%">
                            <Timeline
                                playback={playback}
                                small={small}
                                overlay
                                hasEvents={hasEvents}
                                eventsPlayback={eventsPlayback}
                                window={window}
                                site={site}
                                units={units}
                                allowDownload={allowDownload}
                                onWindowChange={onWindowChange}
                                onSetEventsPlayback={onSetEventsPlayback}
                                onPlaybackChange={onPlaybackChange}
                                onClose={onHistory}
                                parent={fullScreenRef.current || undefined}
                            />
                        </Stack>
                    )}
                </Box>
            </Stack>
            <PickPresetDialog
                presets={presets}
                open={aimPresetDialogOpen}
                onClose={() => setAimPresetDialogOpen(false)}
                onPicked={(p) => {
                    gotoPreset(p)
                    setAimPresetDialogOpen(false)
                }}
                parent={fullScreenRef.current || undefined}
            />
            <PickPresetDialog
                presets={presets}
                open={savePresetDialogOpen}
                onClose={() => setSavePresetDialogOpen(false)}
                onPicked={(p) => {
                    setEdittingPreset(p)
                    setSavePresetDialogOpen(false)
                }}
                parent={fullScreenRef.current || undefined}
            />
            <PickPresetDialog
                presets={presets}
                open={deletePresetDialogOpen}
                onClose={() => setDeletePresetDialogOpen(false)}
                onPicked={(p) => {
                    deletePreset(p)
                    setDeletePresetDialogOpen(false)
                }}
                parent={fullScreenRef.current || undefined}
            />
            <PickTargetDialog
                targets={targets}
                open={aimTargetDialogOpen}
                onClose={() => setAimTargetDialogOpen(false)}
                onPicked={(t) => {
                    aimTarget(t)
                    setAimTargetDialogOpen(false)
                }}
                parent={fullScreenRef.current || undefined}
            />
            <PresetDialog
                preset={edittingPreset || undefined}
                open={!!edittingPreset || addingPreset}
                onClose={() => {
                    setEdittingPreset(null)
                    setAddingPreset(false)
                }}
                onCreate={(p) => {
                    addPreset(p)
                    setAddingPreset(false)
                }}
                onUpdate={(p) => {
                    savePreset(p)
                    setEdittingPreset(null)
                }}
                parent={fullScreenRef.current || undefined}
            />
            <PickCommandDialog
                commands={node?.AuxiliaryCommands || []}
                open={executingCommand}
                onClose={() => setExecutingCommand(false)}
                onPicked={(c) => {
                    executeCommand(c)
                    setExecutingCommand(false)
                }}
                parent={fullScreenRef.current || undefined}
            />
            {popupCamera && (
                <CameraDialog
                    site={site}
                    unit={unit}
                    units={units}
                    camera={camera}
                    playback={playback}
                    disableHistory={disableHistory}
                    toggledHistory={toggledHistory}
                    small={small}
                    initialShowSettings={showSettings}
                    initialShowPTZ={showPTZ}
                    pinned={pinned}
                    motionAlarms={motionAlarms}
                    displayUnit={displayUnit}
                    hasEvents={hasEvents}
                    eventsPlayback={eventsPlayback}
                    window={window}
                    onWindowChange={onWindowChange}
                    onSetEventsPlayback={onSetEventsPlayback}
                    onClose={() => setPopupCamera(false)}
                    onHistory={onHistory}
                    onPlaybackChange={onPlaybackChange}
                    onPin={onPin}
                />
            )}
        </Box>
    )
}
