import { Add, Close, DeleteForever, Edit, FilterAlt, LockPerson, Refresh } from "@mui/icons-material"
import {
    Box,
    Button,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material"
import { useConfirm } from "material-ui-confirm"
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Actor, Operation, Permission, Scope } from "../../api/Authz"
import { Customer } from "../../api/Customer"
import { actorKey, useCustomerActors } from "../../api/portal/hooks/Actors"
import { roleKey, useRoles } from "../../api/portal/hooks/Roles"
import { useScopeThings } from "../../api/portal/hooks/ScopeThings"
import {
    useCreateCustomerPermissionMutation,
    useDeleteCustomerPermissionMutation,
    useGetCustomerPermissionsQuery,
    useUpdateCustomerPermissionMutation,
} from "../../api/portal/Permissions"
import { useCustomerPermission } from "../../auth/AuthorizerProvider"
import { ToShortLocalDateTime } from "../../config/time"
import { LoadingDialog } from "../common/dialog/LoadingDialog"
import { SavedIndicator } from "../common/dialog/SavedIndicator"
import { ErrorRow } from "../common/table/ErrorRow"
import { LoadingRow } from "../common/table/LoadingRow"
import { TextRow } from "../common/table/TextRow"
import { CameraButton } from "../video/CameraButton"
import { ActorChip } from "./ActorChip"
import { EditActorsDialog } from "./EditActorsDialog"
import { EditRolesDialog } from "./EditRolesDialog"
import { EditScopeDialog } from "./EditScopeDialog"
import { FilterActorsDialog } from "./FilterActorsDialog"
import { FilterSitesDialog } from "./FilterSitesDialog"
import { IconText } from "./IconText"
import { RoleChip } from "./RoleChip"
import { ScopeBox } from "./ScopeBox"

const invalidPermission = { id: "", scope: { customers: [] } }
const invalidScope = { customers: [] }

export interface CustomerPermissionsProps {
    customer: Customer
}

const matchesSites = (scope: Scope, siteSet: Set<string>) => {
    if (siteSet.size === 0) {
        return true
    }
    return !!scope.customers?.some((c) => c.all || !!c.sites?.some((s) => s.all || siteSet.has(s.id || "")))
}

const matchesActors = (actors: Actor[] | undefined, actorSet: Set<string>) => {
    if (actorSet.size === 0) {
        return true
    }
    return !!actors?.some((a) => actorSet.has(actorKey(a)))
}

export function CustomerPermissions(props: CustomerPermissionsProps) {
    const { customer } = props

    const [pickingPermissionRole, setPickingPermissionRole] = useState("")
    const [pickingPermissionActor, setPickingPermissionActor] = useState("")
    const [editingPermissionScope, setEditingPermissionScope] = useState("")
    const [editingSiteFilter, setEditingSiteFilter] = useState(false)
    const [editingActorFilter, setEditingActorFilter] = useState(false)
    const [siteFilter, setSiteFilter] = useState<number[]>([])
    const [actorFilter, setActorFilter] = useState<Actor[]>([])

    const { t } = useTranslation()
    const theme = useTheme()
    const confirm = useConfirm()
    const roles = useRoles()
    const customerActors = useCustomerActors(customer.ID)
    const { alarms, gates, query: scopeThingsQuery, refetch: refetchScopeThings } = useScopeThings(customer)

    const permissionsQuery = useGetCustomerPermissionsQuery(customer.ID)
    const refetchPermissions = permissionsQuery.refetch
    const refetchActors = customerActors.refetch
    const refetchRoles = roles.refetch
    const refetch = useCallback(() => {
        refetchPermissions()
        refetchActors()
        refetchRoles()
        refetchScopeThings()
    }, [refetchPermissions, refetchActors, refetchRoles, refetchScopeThings])
    useEffect(() => {
        refetch()
    }, [refetch])

    const [createPermission, createPermissionMutation] = useCreateCustomerPermissionMutation()
    const [deletePermission, deletePermissionMutation] = useDeleteCustomerPermissionMutation()
    const [updatePermission, updatePermissionMutation] = useUpdateCustomerPermissionMutation()

    const allowAddRole = useCustomerPermission(Operation.ADD_CUSTOMER_PERMISSION_ROLE, customer.ID)
    const allowRemoveRole = useCustomerPermission(Operation.REMOVE_CUSTOMER_PERMISSION_ROLE, customer.ID)
    const allowEditScope = useCustomerPermission(Operation.UPDATE_CUSTOMER_PERMISSION, customer.ID)
    const allowAddActor = useCustomerPermission(Operation.ADD_CUSTOMER_PERMISSION_ACTOR, customer.ID)
    const allowRemoveActor = useCustomerPermission(Operation.REMOVE_CUSTOMER_PERMISSION_ACTOR, customer.ID)
    const allowDeletePermission = useCustomerPermission(Operation.DELETE_CUSTOMER_PERMISSION, customer.ID)
    const allowAddPermission = useCustomerPermission(Operation.CREATE_CUSTOMER_PERMISSION, customer.ID)

    const liveSites = useMemo(() => new Set(customer.Units.map((u) => u.SiteID + "")), [customer])
    const sites = useMemo(
        () => new Map(customer.Sites.filter((s) => liveSites.has(s.ID + "")).map((s) => [s.ID, s])),
        [customer, liveSites]
    )
    const units = useMemo(() => new Map(customer.Units.map((u) => [u.ShortName, u])), [customer])
    const permissions = useMemo(() => new Map(permissionsQuery.data?.map((p) => [p.id, p])), [permissionsQuery.data])
    const orderedPermissions = useMemo(() => {
        const siteSet = new Set(
            siteFilter.length > 0 ? siteFilter.map((s) => s + "") : liveSites.size > 0 ? liveSites : [""]
        )
        const actorSet = new Set(actorFilter.map(actorKey))
        return (permissionsQuery.data || [])
            .filter((p) => matchesSites(p.scope, siteSet))
            .filter((p) => matchesActors(p.actors, actorSet))
            .sort((p1, p2) => +p1.id - +p2.id)
    }, [permissionsQuery.data, siteFilter, liveSites, actorFilter])

    const doDeletePermission = (p: Permission) => {
        confirm({
            title: t("confirm.generic"),
            description: t("confirm.delete_permission"),
            confirmationText: t("action.delete"),
            confirmationButtonProps: {
                color: "secondary",
            },
        })
            .then(() => {
                deletePermission({ customerID: customer.ID, permissionID: +p.id })
            })
            .catch(() => {})
    }

    const renderButton = (
        disabled: boolean,
        icon: ReactElement,
        color: "primary" | "secondary",
        tooltip: string,
        onClick: () => void
    ) => (
        <Tooltip title={tooltip} disableInteractive>
            <span>
                <IconButton
                    size="small"
                    color={color}
                    component="span"
                    onClick={onClick}
                    disabled={disabled}
                    sx={{ p: "6px" }}
                >
                    {icon}
                </IconButton>
            </span>
        </Tooltip>
    )

    const renderPermission = (p: Permission, idx: number) => (
        <TableRow key={p.id || JSON.stringify(p.actors)}>
            <TableCell sx={{ alignContent: "start" }}>
                <Typography variant="body2" color={theme.palette.text.secondary}>
                    {idx + 1}
                </Typography>
            </TableCell>
            <TableCell sx={{ alignContent: "start", maxWidth: "400px" }}>
                <Stack direction="row" alignItems="start">
                    <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap" alignItems="start" p={1}>
                        {p.roles ? (
                            roles.customerRoles
                                .filter((r) => !!p.roles?.find((rr) => rr.id === r.id && rr.type === r.type))
                                .map((r) => <RoleChip key={roleKey(r)} role={r} roles={roles} />)
                        ) : (
                            <IconText icon={<Close />} text={t("message.no_roles")} italic />
                        )}
                    </Stack>
                    <Box flexGrow={1}></Box>
                    {renderButton(
                        !p.id || (!allowAddRole && !allowRemoveRole),
                        <Edit />,
                        "primary",
                        t("action.edit_roles_tooltip"),
                        () => setPickingPermissionRole(p.id)
                    )}
                </Stack>
            </TableCell>
            <TableCell sx={{ alignContent: "start" }}>
                <Stack direction="row" alignItems="start">
                    <ScopeBox scope={p.scope} sites={sites} units={units} gates={gates} alarms={alarms} />
                    <Box flexGrow={1}></Box>
                    {renderButton(!p.id || !allowEditScope, <Edit />, "primary", t("action.edit_scope_tooltip"), () =>
                        setEditingPermissionScope(p.id)
                    )}
                </Stack>
            </TableCell>
            <TableCell sx={{ alignContent: "start", maxWidth: "400px" }}>
                <Stack direction="row" alignItems="start">
                    <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap" p={1}>
                        {p.actors ? (
                            <>
                                {customerActors.actors
                                    .filter((a) => !!p.actors?.find((aa) => aa.id === a.id && aa.type === a.type))
                                    .map((a) => (
                                        <ActorChip key={actorKey(a)} actor={a} customerActors={customerActors} />
                                    ))}
                            </>
                        ) : (
                            <IconText icon={<Close />} text={t("message.no_actors")} italic />
                        )}
                    </Stack>
                    <Box flexGrow={1}></Box>
                    {renderButton(
                        !p.id || (!allowAddActor && !allowRemoveActor),
                        <Edit />,
                        "primary",
                        t("action.edit_actors_tooltip"),
                        () => setPickingPermissionActor(p.id)
                    )}
                </Stack>
            </TableCell>
            <TableCell sx={{ alignContent: "start", alignItems: "end" }}>
                <Typography noWrap variant="body2" fontStyle={p.expiration ? "normal" : "italic"} p={1}>
                    {p.expiration ? ToShortLocalDateTime(p.expiration) : t("message.never")}
                </Typography>
            </TableCell>
            <TableCell sx={{ alignContent: "start", alignItems: "end" }} align="right">
                {renderButton(
                    !p.id || !allowDeletePermission,
                    <DeleteForever />,
                    "secondary",
                    t("action.delete_permission_tooltip"),
                    () => doDeletePermission(p)
                )}
            </TableCell>
        </TableRow>
    )

    const renderBody = () => {
        if (permissionsQuery.error) {
            return <ErrorRow error={permissionsQuery.error} />
        }
        if (permissionsQuery.isLoading) {
            return <LoadingRow />
        }
        if (!permissionsQuery.data?.length) {
            return <TextRow text={t("message.no_customer_permissions")} />
        }
        return orderedPermissions.map(renderPermission)
    }

    return (
        <>
            <Stack sx={{ p: 4, alignItems: "center", width: "100%" }}>
                <Box sx={{ width: "min(100%,1600px)" }}>
                    <Stack spacing={1}>
                        <Stack direction="row" spacing={1} alignItems="center" pb={1}>
                            <LockPerson htmlColor={theme.palette.text.secondary} />
                            <Typography variant="h6" sx={{ flexGrow: 1 }} noWrap>
                                {t("dialog.customer_permissions.header", { customer: customer.DisplayName })}
                            </Typography>
                            <Box flexGrow={1} />
                            {renderButton(
                                permissionsQuery.isFetching,
                                <Refresh />,
                                "primary",
                                t("action.reload_permissions_tooltip"),
                                refetch
                            )}
                        </Stack>
                        <TableContainer component="div" style={{ overflow: "auto", paddingBottom: 5 }}>
                            <Table aria-label="permissions table" size="small" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t("field.id")}</TableCell>
                                        <TableCell>{t("field.roles")}</TableCell>
                                        <TableCell>
                                            <Stack direction="row" alignItems="center" spacing={1}>
                                                <Typography variant="body2" fontWeight="bold">
                                                    {t("field.scope")}
                                                </Typography>
                                                <CameraButton
                                                    tooltip="action.filter_target_tooltip"
                                                    toggled={siteFilter.length > 0}
                                                    toggledColor={theme.palette.warning.main}
                                                    icon={<FilterAlt />}
                                                    onClick={() => setEditingSiteFilter(true)}
                                                />
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            <Stack direction="row" alignItems="center" spacing={1}>
                                                <Typography variant="body2" fontWeight="bold">
                                                    {t("field.actors")}
                                                </Typography>
                                                <CameraButton
                                                    tooltip="action.filter_actors_tooltip"
                                                    toggled={actorFilter.length > 0}
                                                    toggledColor={theme.palette.warning.main}
                                                    icon={<FilterAlt />}
                                                    onClick={() => setEditingActorFilter(true)}
                                                />
                                            </Stack>
                                        </TableCell>
                                        <TableCell>{t("field.expiration")}</TableCell>
                                        <TableCell>{t("field.actions")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>{renderBody()}</TableBody>
                            </Table>
                        </TableContainer>
                        <Stack direction="row">
                            <SavedIndicator
                                mutations={[
                                    createPermissionMutation,
                                    deletePermissionMutation,
                                    updatePermissionMutation,
                                ]}
                            />
                            <Box flexGrow={1} />
                            <Tooltip title={t("action.add_permission_tooltip")} disableInteractive>
                                <span>
                                    <Button
                                        startIcon={<Add />}
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        disabled={!allowAddPermission}
                                        onClick={() =>
                                            createPermission({
                                                customer_id: "" + customer.ID,
                                                sites: [
                                                    {
                                                        id: "0",
                                                        all: true,
                                                        units: [],
                                                    },
                                                ],
                                            })
                                        }
                                    >
                                        {t("action.add")}
                                    </Button>
                                </span>
                            </Tooltip>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
            <LoadingDialog
                queries={[...roles.roleQueries, ...customerActors.actorQueries, permissionsQuery, scopeThingsQuery]}
                mutations={[createPermissionMutation, deletePermissionMutation, updatePermissionMutation]}
            />
            {pickingPermissionRole && (
                <EditRolesDialog
                    customerID={customer.ID}
                    permission={permissions.get(pickingPermissionRole) || invalidPermission}
                    roles={roles}
                    onClose={() => setPickingPermissionRole("")}
                />
            )}
            {pickingPermissionActor && (
                <EditActorsDialog
                    customerID={customer.ID}
                    permission={permissions.get(pickingPermissionActor) || invalidPermission}
                    customerActors={customerActors}
                    onClose={() => setPickingPermissionActor("")}
                />
            )}
            {editingPermissionScope && (
                <EditScopeDialog
                    initialScope={permissions.get(editingPermissionScope)?.scope || invalidScope}
                    customer={customer}
                    sites={sites}
                    units={units}
                    gates={gates}
                    alarms={alarms}
                    onClose={() => setEditingPermissionScope("")}
                    onSave={(scope) =>
                        updatePermission({
                            customerID: customer.ID,
                            request: {
                                id: editingPermissionScope,
                                sites: scope.customers?.at(0)?.sites || [],
                            },
                        })
                    }
                />
            )}
            {editingActorFilter && (
                <FilterActorsDialog
                    initialFilter={actorFilter}
                    customerActors={customerActors}
                    onClose={() => setEditingActorFilter(false)}
                    onSet={setActorFilter}
                />
            )}
            {editingSiteFilter && (
                <FilterSitesDialog
                    initialFilter={siteFilter}
                    sites={sites}
                    onClose={() => setEditingSiteFilter(false)}
                    onSet={setSiteFilter}
                />
            )}
        </>
    )
}
