import { Grid, Stack, Switch, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material"
import { t } from "i18next"
import { useMemo } from "react"
import { Operation } from "../../api/Authz"
import { offeringOperation, OfferingType, Site, Unit } from "../../api/Customer"
import { Live } from "../../api/Video"
import { useGatePermission, useUnitPermission } from "../../auth/AuthorizerProvider"
import { CameraDelay } from "../../config/time"
import { noAlarms } from "../../hooks/flags"
import { switchState, thingIndex, useIoTCommands, ValidValue } from "../../services/IoT"
import { OneHourWindow } from "../common/TimelineChoice"
import { LabelledCamera } from "../video/LabelledCamera"

export interface GateInfoProps {
    gate: ValidValue
    site: Site
    unit: Unit
}

export const GateInfo = (props: GateInfoProps) => {
    const { gate, site, unit } = props

    const theme = useTheme()
    const isMD = useMediaQuery(theme.breakpoints.down("md"))
    const { send } = useIoTCommands(site)

    const id = thingIndex(gate.thing)
    const allowGateOpen = useGatePermission(Operation.OPEN_GATE, unit, id)
    const allowGateClose = useGatePermission(Operation.CLOSE_GATE, unit, id)
    const allowVideo = useUnitPermission(offeringOperation(OfferingType.Video), unit)

    const singleUnit = useMemo(() => [unit], [unit])

    const cameras = useMemo(() => {
        const cameraIDs = gate.annotations
            ?.get("cameras")
            ?.split(",")
            .map((s) => +s.trim())
            .filter((n) => isFinite(n))
        if (cameraIDs === undefined || cameraIDs?.length === 0) {
            return []
        }
        const idFilter = new Set(cameraIDs)
        return unit.UnitConfig.Cameras?.filter((c) => idFilter.has(c.ID)) || []
    }, [gate.annotations, unit])
    const hasCameras = allowVideo && cameras.length > 0

    const displayName = gate.annotations?.get("display_name")
    const label = displayName || t("unit.gate_display_name", { id: id })
    const tooltip = `${t("unit.gate_display_name", { id: id })}${!displayName ? "" : ": " + displayName}.`
    const open = switchState(gate)

    return (
        <>
            <Grid item xs={12} sm={hasCameras ? 12 : 6} md={hasCameras ? 12 : 4} lg={hasCameras ? 12 : 3}>
                <Stack
                    direction="row"
                    px={1}
                    height="100%"
                    sx={{ alignItems: "center", backgroundColor: theme.palette.background.paper }}
                >
                    <Stack
                        direction="row"
                        sx={{
                            alignItems: "center",
                            flexGrow: 1,
                            overflow: "hidden",
                        }}
                    >
                        {open !== undefined && (
                            <Tooltip
                                title={open ? t("access.close_gate_tooltip") : t("access.close_gate_tooltip")}
                                disableInteractive
                            >
                                <span>
                                    <Switch
                                        disabled={open ? !allowGateClose : !allowGateOpen}
                                        checked={open}
                                        onClick={() => send(gate.thing, gate.item, open ? 0 : 1)}
                                    />
                                </span>
                            </Tooltip>
                        )}
                        <Tooltip title={tooltip} disableInteractive>
                            <Typography
                                sx={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                {label}
                            </Typography>
                        </Tooltip>
                    </Stack>
                </Stack>
            </Grid>
            {cameras.map((camera, i) => (
                <Grid key={`camera-${camera.ID}`} item xs={12} md={6}>
                    <LabelledCamera
                        site={site}
                        unit={unit}
                        units={singleUnit}
                        camera={camera}
                        playback={Live}
                        disableHistory={true}
                        toggledHistory={false}
                        small={false}
                        pinned={false}
                        motionAlarms={noAlarms}
                        hasEvents={false}
                        eventsPlayback={false}
                        window={OneHourWindow}
                        delay={CameraDelay(i)}
                        onSetEventsPlayback={() => {}}
                        onHistory={() => {}}
                        onPlaybackChange={() => {}}
                    />
                </Grid>
            ))}
            {cameras.length % 2 === 1 && !isMD && <Grid item xs={6} />}
        </>
    )
}
